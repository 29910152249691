import axios from 'axios';

// ----------------------------------------------------------------------

const inventoryAxiosInstance = axios.create({ baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_INVETORY_API_PROD : process.env.REACT_APP_INVETORY_API });

inventoryAxiosInstance.interceptors.request.use(function (req) {
  const accessToken = window.localStorage.getItem('invhash')
  if (accessToken) {
    req.headers.authorization = `Bearer ${accessToken}`
  }
  return req;
})

inventoryAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('invhash')
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);


export default inventoryAxiosInstance;
