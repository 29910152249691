import { Row, Col, Container, Card, Button, Table, Badge, Dropdown, Form, Spinner, FormGroup } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/table/Paginator";
import { useEffect, useState } from "react";
import DrewsModal from "../../components/modal/DrewsModal";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import formatCurrency from "../../utils/currencyFormmter";
import InventoryFilter from "../../components/InventoryFilter";
import { useNavigate } from "react-router-dom";
import { getProductPrices, getProducts, loginCustomer, restock, setupInventory } from "../../redux/slices/inventory.slice";

const InventoryListing = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialState = {
        loading: false,
        show_vendor_form: false,
        showStockForm: false,
        showForm: false,
        analytics: {},
        paginationData: {next: '', prev: ''},
        payload: {
            to_currency: 'NGN', 
            currency: 'USD', 
            amount: 1,
            page: 1,
            from_date: '',
            to_date: '',
            status: '',
            download: '',
            filter_by: '',
            sort_by: '',
            filter: '',
            gateway: 'FasePay',
        },
        errors: {},
        suppliers: [],
        products: [],
        restockMode: false,
        product: {},
        pricelist: [],
    }

    const [state, setState] = useState(initialState)
    const [loading, setLoading] = useState(false)
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    const [request_status, setRequestStatus] = useState(false)

    const { invLogin } = useSelector((state) => state.inventory)
    const { user } = useSelector((state) => state.auth)
    const { business } = useSelector((state) => state.user)


    useEffect(() => {
        handleCreateOrganisation()
    }, [0])

    useEffect(() => {
        loadStocks()
    }, [state.payload.page])

    const manageProduct = (item, option) => {
        if(option === 'restock') {
            setState((prevState) => ({
                ...prevState,
                restockMode: !prevState.restockMode, product: item
            }))
        }
        else if(option === 'view') {
            navigate('/inventory/product-info/'+item.id)
        }
    }

    const handleInput = (event) => {
        const {name, value} = event.target
         if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'));
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: newValue}}))
        }
        else {
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: value}}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setState((prevState) => ({ ...prevState, errors:{...prevState.errors, [name]: `${name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} is required` }}))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setState((prevState) => ({ ...prevState, errors: { ...prevState.errors, [name]: 'Please enter a valid email address'}}))
        }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setState((prevState) => ({ ...prevState, errors: {...prevState.errors, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }}))
        }
        else {
            event.target.style = "border-style: transparent"
            delete state.errors[name]
            setState((prevState) => ({ ...prevState, errors: state.errors }))
        }
    }
    
    const handlePagination = (data) => {
        setState((prevState) => ({...prevState, payload: {...prevState.payload,  page: data}}))
    }

    const loadStocks = () => {
        setLoading(true)
        dispatch(getProducts(state.payload))
        .then((response) => {
            const result = response.payload?.data?.data
            if(result) {
                setState((prevState) => ({...prevState, products:  result})) 
            }

            // if(response.payload?.next || response.payload?.previous) {
            //     const data = {
            //         next: response.payload?.next,
            //         prev: response.payload?.previous,
            //     }
            //     setpaginationData(data)
            // }
        })
        .finally(() => setLoading(false) )
    }

    const handleLogin = () => {
        if(invLogin) {
            return;
        }
        dispatch(loginCustomer({email: user?.email, password: business?.merchant_id}))
        .then((response) => {
            if(response.payload?.data) {
            }
        })
    }

    const handleCreateOrganisation = () => {
        dispatch(setupInventory({
            business_name:  business?.store_name,
            first_name: user?.first_name,
            last_name: user?.last_name,  
            email: user?.email,
            phone_number: user?.mobile,   
            wallx_id: business?.merchant_id,
            user_type: user?.user_type,
        }))
        .then((response) => {
            if(response.payload?.data) {
                handleLogin()
            }
        })
    }

    const handleSearch = () => {
        // console.log(state.payload)
        loadStocks()

    }

    const sendRestockRequest = () => {
        setRequestStatus(true)
        dispatch(restock({product_id: state.product.id, reason: state.payload.reason,  quantity: state.payload.quantity}))
        .then((response) => {
            if(response.payload?.status === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                .then(() => {
                    setState((prevState) => ({
                        ...prevState,
                        restockMode: false
                    }))
                })
            }
            else if(response.payload?.status === false) {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
        .finally(() => setRequestStatus(false))
    }

    const loadPrices = () => {
        setLoading(true)
        dispatch(getProductPrices())
        .then((response) => {
            const result = response.payload?.data
            if(result) {
                setState((prevState) => ({...prevState, pricelist:  result})) 
            }
        })
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Col md={12} className="row align-items-center justify-content-between flex-wrapd">
                    <div className="col-md-4">
                        <h3 className="m-0">Inventory</h3>
                        <p>
                            <small>Manage stock level and restock</small>
                        </p>                        
                    </div>
                    {/* <div className="col-md-8 text-right">
                        <Button 
                            type="button" 
                            className="border-0 mb-2 px-3"
                            onClick={ () => setState((prevState) => ({...prevState, showStockForm: !state.showStockForm}))}
                        >
                            <FontAwesomeIcon icon={'plus'}/>
                            <span className="ps-2">Add Stock</span>
                        </Button>
                        <Button 
                            type="button" 
                            className="border-0 mb-2 px-3 ms-2"
                            onClick={ () => setState((prevState) => ({...prevState, 'show_vendor_form': !state.show_vendor_form}))}
                        >
                            <FontAwesomeIcon icon={'user-plus'}/>
                            <span className="ps-2">Add Supplier</span>
                        </Button>
                        <Button 
                            type="button" 
                            variant="primary" 
                            className="border-0 mb-2 ms-2"
                            onClick={ () => navigate('/stock-request')}
                        >
                            <FontAwesomeIcon icon={'box'} />
                            <span className="ps-2">Stock Request</span>
                        </Button>
                    </div> */}
                </Col>

                <div className="mt-4">
                    <Row className="mb-4 align-items-center justify-content-between">
                        <div className="col-md-5">
                            <h5 className="mb-2">Search Inventory</h5>
                        </div>
                        <div className="">
                            <InventoryFilter 
                                handleInput={handleInput}
                                handleSearchSubmit={handleSearch}
                            />
                        </div>
                    </Row>

                    <Card className="border-0 shadow-sm rounded-14">
                        <Card.Body className="table-responsive-lg">
                            <Table size="sm" className="table-color table-stripedd">
                                    <thead>
                                        <tr>
                                            <th>SKU</th>
                                            <th>Item Name</th>
                                            <th>Quantity</th>
                                            <th>Buying Price(₦)</th>
                                            <th>Selling Price(₦) 
                                                <button type="button" className="text-primary btn-plain" onClick={() => navigate('/inventory/pricelist')}><small>Price list</small></button>     
                                            </th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody className="data-container">
                                        {
                                            loading ? (
                                                <div className="in-page-loader">
                                                    <Spinner size="lg" variant="primary"/>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            state.products?.length ? (
                                                state.products?.map((item, index) => (
                                                    <tr key={index}> 
                                                        <td>
                                                        <button 
                                                                type="button" 
                                                                className="btn btn-plain text-primary font-bold"
                                                                onClick={() => navigate('/inventory/product-info/'+item.id)}
                                                            >
                                                                {item.sku}
                                                            </button> 
                                                        </td>
                                                        <td>{item.name}</td>
                                                        <td> {formatCurrency(item.quantity).formatted}  </td>
                                                        <td>{formatCurrency(item.price).with_currency} </td>
                                                        <td>{formatCurrency(item.sales_price).with_currency} </td>
                                                        <td>
                                                            <div className="d-flex m-0 align-items-center justify-content-between">
                                                                <Badge 
                                                                    className={item.quantity > item.restock_level ? 'badge-active' : 
                                                                    (item.quantity < item.restock_level ? 'badge-failed' : 
                                                                    (item.quantity === 0 ? 'badge-used' : 'badge-pending'))}
                                                                >
                                                                    <FontAwesomeIcon icon={'dot-circle'}
                                                                        color={
                                                                            item.quantity > item.restock_level ? "#008000" : 
                                                                            (item.quantity < item.restock_level ? "#EB5757" : 
                                                                            (item.quantity === 0 ? "#828282" : "#FAB941"))
                                                                        }
                                                                    />
                                                                    <span className="ps-1">
                                                                        {
                                                                            item.quantity > item.restock_level ? "In stock" : 
                                                                            (item.quantity < item.restock_level ? "Low stock" : 
                                                                            (item.quantity === 0 ? "Out of stock" : ""))
                                                                        }
                                                                    </span>
                                                                </Badge> 
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                        <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                    </Dropdown.Toggle>
        
                                                                    <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                        <Dropdown.Item 
                                                                            eventKey={1} 
                                                                            className="text-primary d-flex align-items-center gap-1" 
                                                                            onClick={() => manageProduct(item, 'view')}
                                                                        >
                                                                        <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Details
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item 
                                                                            eventKey={2} 
                                                                            className="text-primary d-flex align-items-center gap-1"
                                                                            onClick={(e) => manageProduct(item, 'restock')}
                                                                        >
                                                                            <i className="lni lni-question-circle"></i> Stock Request
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                            : 
                                            (
                                                <tr>
                                                    <td colSpan={5} className="text-center text-muted">No data found</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        state.products?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator
                                    next={paginationData.next}
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </div>

                <DrewsModal
                    show={state.restockMode}
                    onHide={() => setState((prevState) => ({...prevState, restockMode: false}))}
                    size="md"
                    dismissable={true}
                    title={`${state.product.sku} - ${state.product.name}`}
                >
                    <Row>
                        <FormGroup as={Col} md={12} sm={12} className="mb-3">
                            <span> Quantity</span> <span className="text-danger">*</span>
                            <Form.Control
                                type="number"
                                name="quantity"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                                placeholder="Enter your Product quantity"
                                required
                                min={1}
                            />
                            {
                                state.errors['quantity'] && 
                                <Form.Text className="text-danger">{state.errors['quantity']}</Form.Text>
                            }                            
                        </FormGroup>

                        <FormGroup as={Col} md={12} sm={12} className="mb-3">
                            <span>Reason for restock</span><span className="text-danger">*</span>
                            <textarea
                                name="reason"
                                onChange={handleInput}
                                onKeyUp={handleValidation}
                                placeholder="Reasons for product restock"
                                className="form-control"
                                required
                            ></textarea>
                            {
                                state.errors['reason'] && 
                                <Form.Text className="text-danger">{state.errors['reason']}</Form.Text>
                            }                       
                        </FormGroup>
                        <FormGroup as={Col} md={12} sm={12}>
                            <div className="d-grid">
                                <Button variant="primary" type="submit" onClick={sendRestockRequest}>
                                    Submit <Spinner animation={ request_status ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </div>
                        </FormGroup>
                    </Row>
                </DrewsModal>
            </Container>
        </Layout>
    );
}

export default InventoryListing;