import { useState } from 'react';

const RatingStar = (props) => {

  const maxRating = 5.0;

  const [rating, setRating] = useState(props.count || 1.0);

  const handleStarClick = (starIndex) => {
    setRating(starIndex + 1.0);
  };

    return (
        <>
            { props.showCounter ? (
                <h3 className="text-primary font-bold">{parseFloat(props.count)}</h3>
            ) : null }
            <div>
                {[...Array(maxRating)].map((_, index) => (
                    <span
                        key={index}
                        onClick={() => handleStarClick(index)}
                        style={{
                            cursor: 'pointer',
                            color: index < props.count ? 'gold' : 'gray',
                            fontSize: props.size || '24px',
                        }}
                        >
                        &#9733;
                    </span>
                ))}
            </div>
        </>
    );
}

export default RatingStar;