import { Row, Col, Container, Card, Button, Form, Spinner, FormGroup, Dropdown , Table} from "react-bootstrap";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import DrewsModal from "../../components/modal/DrewsModal";
import WithdrawalForm from "../../components/WIthdrawal";
import AlertModal from "../../components/modal/AlertModal";
import photo from '../../assets/images/gtb.svg';
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addUser, deleteMember, getMembers, manageMember } from "../../redux/slices/users";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Managers = () => {

    const dispatch = useDispatch()

    const { message } = useSelector((state) => state.message)

    const [showForm, setShowForm] = useState(false)
    const [success, setSuccessRespone] = useState(false)
    const [payload, setPayload] = useState({})
    const [loading, setLoading] = useState(false)
    const [msg, setMessage] = useState({status: false, message: null})
    const [errors, setErrors] =  useState({})
    const [members, setMembers] =  useState([])

    useEffect(() => {
        loadMembers()
        if(message?.status && message?.status === 'True') {
            setMessage({message: message?.message, status: true})
        }
        else if(message?.status && message?.status === 'False') {
            setMessage({message: message?.message, status: false})
        }
        // console.log('msg', msg)
    },[dispatch])
    

    const handleInput = (event) => {
        const { name, value } = event.target;

        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;

        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
    }

    const loadMembers = () => {
        dispatch(getMembers())
        .then((b) => {
            if(b.payload.data) {
                setMembers(b.payload.data)
            }
        })
    }

    const createUser = () => {
        if(!payload.first_name && !payload.last_name && !payload.email && !payload.phone) { return }
        setLoading(true)
        dispatch(addUser(payload))
        .then((r) => {
            // console.log(r)
            if(r.payload?.success){
                setSuccessRespone(true)
                setShowForm(false)
                setMessage({status: true, message: r.payload.message})
                setPayload({})
                loadMembers()
            } else {
                setSuccessRespone(false)
                setMessage({status: false, message: r.payload?.message})
            }
        })
        .finally(() => setLoading(false))
    }

    const confirmStatusChange = (id, status) => {
        setLoading(true)
        dispatch(manageMember({user_id: id, activate: status})).then((res) => {
            if(res.payload?.success){
                setSuccessRespone(true)
                setMessage({status: true, message: res.payload?.message})
                setShowForm(false)
                loadMembers()
            } else {
                setSuccessRespone(false)
                setMessage({status: false, message: res.payload?.message})
            }
        })
        .finally(() => setLoading(false))
    }
    

    const confirmDelete = (id) => {
        setLoading(true)
        dispatch(deleteMember(id)).then((res) => {
            if(res.payload?.success){
                setSuccessRespone(true)
                setMessage({status: true, message: res.payload?.message})
                setShowForm(false)
                loadMembers()
            } else {
                setSuccessRespone(false)
                setMessage({status: false, message: res.payload?.message})
            }
        })
        .finally(() => setLoading(false))
    }

   const promptDelete = (member) => {
        Swal.fire({ 
            icon: 'warning', 
            text: `Are you sure you want to delete ${member.name}?`,
            confirmButtonText: 'Yes, Proceed',
            showCancelButton: true,
            cancelButtonText: 'Cancel'
        })
        .then((response) => {
            if(response.isConfirmed) {
                confirmDelete(member.id)
            }
        });
    }

   const manageStatus = (member) => {
        let status = member.active ? 'desctivate' : 'activate';
        Swal.fire({ 
            icon: 'warning', 
            text: `Are you sure you want to ${status} ${member.name}?`,
            confirmButtonText: 'Yes, Proceed',
            showCancelButton: true,
            cancelButtonText: 'Cancel'
        })
        .then((response) => {
            if(response.isConfirmed) {
                confirmStatusChange(member.id, !member.active)
            }
        });
    }

    return (
        <Layout>
            <Container className="mt-5 px-4 mb-4">
                <Col md={11} lg={11} sm={12} className="d-flex align-items-center justify-content-between mb-5 mx-auto">
                    <h3>Managers</h3>
                    <Button 
                        type="button" 
                        variant="primary" 
                        size="lg"
                        onClick={ () => setShowForm(true) }
                    >
                        <FontAwesomeIcon icon="plus" style={{ fontSize: 20 }} />
                        <span className="ps-2">Add Manager</span>
                    </Button>
                </Col>
                <Row className="justify-content-center">
                    <Col md={11}>
                        <Card className="border-0 shadow-sm rounded-16">
                            <Card.Body className="py-4 pe-3 ps-3 table-responsive-lg">
                            <Table size="sm" className="table-color">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email Address</th>
                                        <th>Phone Number</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        members?.length ? (
                                            members.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <p className="m-0 d-flex gap-2 align-items-center">
                                                            <img src={item.photo || 'https://via.placeholder.com/70'} alt={item.name} width="35" className="rounded-circle"/>
                                                            <strong>
                                                                {item.name}
                                                            </strong>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        {item.email}
                                                    </td>
                                                    <td>
                                                        {item.mobile_number}
                                                    </td>
                                                    <td>
                                                        <p className="d-flex m-0 align-items-center justify-content-between">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="light" className="border-0 bg-white" size="sm" id="dropdown-basic">
                                                                    <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                </Dropdown.Toggle>
    
                                                                <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                    {
                                                                        item.active ? (
                                                                            <Dropdown.Item 
                                                                                eventKey={1} 
                                                                                className="d-flex align-items-center gap-1 text-danger" 
                                                                                onClick={ () => manageStatus(item) }
                                                                            >
                                                                                <FontAwesomeIcon icon="ban" style={{fontSize: "20px"}}/> Deactivate
                                                                            </Dropdown.Item>
                                                                        )
                                                                        :
                                                                        (
                                                                            <Dropdown.Item 
                                                                                eventKey={1} 
                                                                                className="d-flex align-items-center gap-1 text-success" 
                                                                                onClick={ () => manageStatus(item) }
                                                                            >
                                                                                <FontAwesomeIcon icon="circle-check" style={{fontSize: "20px"}}/> Activate
                                                                            </Dropdown.Item>
                                                                        )
                                                                    }
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item 
                                                                        eventKey={2} 
                                                                        className="d-flex align-items-center gap-1 text-danger"
                                                                        onClick={ () => promptDelete(item) }
                                                                    >
                                                                        <FontAwesomeIcon icon="trash" /> Delete
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </p>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                        :
                                        (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <DrewsModal
                    show={showForm}
                    onHide={() => setShowForm(false)}
                    size="md"
                    dismissable={true}
                    title="New Manager"
                >
                    <Form noValidate>
                        <Row>
                            <FormGroup as={Col} md={12}>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control 
                                    type="text"
                                    name="first_name"
                                    placeholder="First Name"
                                    onKeyUp={handleInput}
                                    required
                                />
                                {
                                    errors['first_name'] && 
                                    <Form.Text className="text-danger">{errors['first_name']}</Form.Text>
                                }
                            </FormGroup>
                            <FormGroup as={Col} md={12} className="mt-3">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control 
                                    type="text"
                                    name="last_name"
                                    placeholder="Last Name"
                                    required
                                    onKeyUp={handleInput}
                                />
                                {
                                    errors['last_name'] && 
                                    <Form.Text className="text-danger">{errors['last_name']}</Form.Text>
                                }
                            </FormGroup>
                            <FormGroup as={Col} md={12} className="mt-3">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control 
                                    type="email"
                                    name="email"
                                    pattern=".+@gmail\.com"
                                    placeholder="Email Address"
                                    required
                                    onKeyUp={handleInput}
                                />
                                {
                                    errors['email'] && 
                                    <Form.Text className="text-danger">{errors['email']}</Form.Text>
                                }
                            </FormGroup>
                            <FormGroup as={Col} md={12} className="mt-3">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control 
                                    type="tel"
                                    name="phone"
                                    placeholder="Phone Number"
                                    required
                                    onKeyUp={handleInput}
                                    maxLength={11}
                                    minLength={11}
                                />
                                {
                                    errors['phone'] && 
                                    <Form.Text className="text-danger">{errors['phone']}</Form.Text>
                                }
                            </FormGroup>
                            <FormGroup as={Col} md={12} className="mt-3">
                                <Form.Text
                                    className={msg.status ? 'text-success' : 'text-danger'}
                                >
                                    {msg.message}
                                </Form.Text>
                            </FormGroup>
                            <FormGroup as={Col} md={12} className="mt-3">
                                <Button 
                                    type="button" 
                                    variant="primary"
                                    className="form-control"
                                    onClick={createUser}
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    Add Manager <Spinner animation={ loading ? "border" : null} variant="light" role="status" size="sm"></Spinner>
                                </Button>
                            </FormGroup>
                        </Row>
                    </Form>
                </DrewsModal>

                <AlertModal
                    title="Successful!"
                    text={msg.message}
                    buttonText="Ok"
                    onHide={() => setSuccessRespone(false)}
                    onClick={() => setSuccessRespone(false)}
                    show={success}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                    <path d="M32 4C26.4621 4 21.0486 5.64217 16.444 8.71885C11.8395 11.7955 8.25064 16.1685 6.13139 21.2849C4.01213 26.4012 3.45764 32.0311 4.53802 37.4625C5.61841 42.894 8.28515 47.8831 12.201 51.799C16.1169 55.7149 21.106 58.3816 26.5375 59.462C31.969 60.5424 37.5988 59.9879 42.7151 57.8686C47.8315 55.7494 52.2045 52.1605 55.2812 47.556C58.3578 42.9514 60 37.5379 60 32C60 24.5739 57.05 17.452 51.799 12.201C46.548 6.94999 39.4261 4 32 4ZM47.42 23.42L27.42 43.42C27.2341 43.6075 27.0129 43.7562 26.7692 43.8578C26.5254 43.9593 26.264 44.0116 26 44.0116C25.736 44.0116 25.4746 43.9593 25.2309 43.8578C24.9871 43.7562 24.7659 43.6075 24.58 43.42L14.58 33.42C14.3935 33.2335 14.2456 33.0121 14.1447 32.7685C14.0438 32.5249 13.9918 32.2637 13.9918 32C13.9918 31.4674 14.2034 30.9566 14.58 30.58C14.9566 30.2034 15.4674 29.9918 16 29.9918C16.5326 29.9918 17.0434 30.2034 17.42 30.58L26 39.18L44.58 20.58C44.9566 20.2034 45.4674 19.9918 46 19.9918C46.5326 19.9918 47.0434 20.2034 47.42 20.58C47.7966 20.9566 48.0082 21.4674 48.0082 22C48.0082 22.5326 47.7966 23.0434 47.42 23.42Z" fill="#B2B1FF"/>
                    </svg>
                    
                </AlertModal>
            </Container>
        </Layout>
    );
}

export default Managers;