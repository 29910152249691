const dateFormatter = (timestamp) => {
    // Check if the timestamp is a valid number

    if(timestamp === undefined || timestamp === '') {
      return '';
    }

    const date = new Date(timestamp);

    // Format the date to a human-readable format
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    return formattedDate;
    
    // const date = new Date(timestamp);

    // const options = {
    //   year: 'numeric',
    //   month: 'long',
    //   day: 'numeric',
    //   hour: 'numeric',
    //   minute: 'numeric',
    // };

    const formatter = new Intl.DateTimeFormat('en-GB', options);

    return formatter.format(date);
    
}

export default dateFormatter;
