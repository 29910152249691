import { Row, Col, Container, Card, Table, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import Paginator from "../../components/table/Paginator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoyaltyPaycodeHistory } from "../../redux/slices/account.service";
import formatCurrency from "../../utils/currencyFormmter";
import dateFormatter from "../../utils/dateFormatter";
import RecordFilter from "../../components/RecordFilter";
import { useParams } from "react-router-dom";

const LoyaltyVoucherHistory = () => {

    const { paycode_id } = useParams()

    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth)

    const [payload, setPayload] = useState({
        page: 1,
        from_date: '',
        to_date: '',
        status: '',
        download: '',
        filter_by: '',
        sort_by: '',
        filter: '',
        gateway: '',
    })
    const [loading, setLoading] = useState(false)
    const [paycodes, setPaycodes] = useState([])
    const [analytics, setAnalytics] = useState({})
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})

    useEffect(() => {
        loadPaycodes()
    }, [payload.page])

    const handleInput = (event) => {
        const {name, value} = event.target
        if(name === 'dob') {
            const dateParts = value.split('-');
            const dob = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
            setPayload((prevState) => ({...prevState, [name]: dob}))
        }
        else if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^\d.]/g, ''))
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
        }
    }

    const loadPaycodes = () => {
        let request_payload = payload
        request_payload.paycode_id = paycode_id ?? ''
        request_payload.user_type = user?.user_type
        setLoading(true)
        dispatch(getLoyaltyPaycodeHistory(request_payload))
        .then((response) => {
            if(response.payload?.results) {
                setPaycodes(response.payload.results)
            }
            if(response.payload?.analytics) {
                setAnalytics(response.payload.analytics)
            }

            if(response.payload?.next || response.payload?.previous) {
                const data = {
                    next: response.payload?.next,
                    prev: response.payload?.previous,
                }
                setpaginationData(data)
            }
        })
        .finally(() => setLoading(false))
    }
    
    const handlePagination = (data) => {
        setPayload({...payload, page: data})
    }

    const handleSearch = () => {
        loadPaycodes()
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Col md={12} className="d-flex align-items-center justify-content-between flex-wrap">
                    <div>
                        <h3 className="m-0">Loyalty Voucher Transactions History</h3>
                    </div>
                </Col>

                <div className="mt-4">
                    {
                        loading ? (
                            <div className="page-loader">
                                <Spinner size="lg" variant="primary"/>
                            </div>
                        ) : null
                    }
                    {
                        paycode_id && (
                            <Row className="data-card mb-4">
                                <Col md={3} className="mb-2">
                                    <Card className="border-0 shadow-sm bg--light">
                                        <Card.Body className="d-flex align-items-center gap-3 p-3">
                                            <div className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                    <path d="M11.25 8.62516C11.25 8.27999 10.9702 8.00016 10.625 8.00016H5.625C5.27982 8.00016 5 8.27999 5 8.62516C5 8.97034 5.27982 9.25016 5.625 9.25016H10.625C10.9702 9.25016 11.25 8.97034 11.25 8.62516Z" fill="#4F4F4F"/>
                                                    <path d="M10.4167 11.1252C10.4167 10.78 10.1368 10.5002 9.79167 10.5002H5.625C5.27982 10.5002 5 10.78 5 11.1252C5 11.4703 5.27982 11.7502 5.625 11.7502H9.79167C10.1368 11.7502 10.4167 11.4703 10.4167 11.1252Z" fill="#4F4F4F"/>
                                                    <path d="M10.625 13.0002C10.9702 13.0002 11.25 13.28 11.25 13.6252C11.25 13.9703 10.9702 14.2502 10.625 14.2502H5.625C5.27982 14.2502 5 13.9703 5 13.6252C5 13.28 5.27982 13.0002 5.625 13.0002H10.625Z" fill="#4F4F4F"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 18.6252H15.8333C17.099 18.6252 18.125 17.5992 18.125 16.3335V11.7502C18.125 11.405 17.8452 11.1252 17.5 11.1252H14.7917V4.6195C14.7917 3.43333 13.4509 2.74335 12.4857 3.4328L12.3398 3.537C11.6894 4.00157 10.8084 4.00001 10.1549 3.53325C9.06839 2.75714 7.59828 2.75714 6.51173 3.53325C5.85826 4.00001 4.97725 4.00157 4.32685 3.537L4.18097 3.4328C3.21575 2.74335 1.875 3.43333 1.875 4.6195V15.5002C1.875 17.2261 3.27411 18.6252 5 18.6252ZM7.23827 4.55042C7.89021 4.08476 8.77646 4.08476 9.42839 4.55042C10.5134 5.32544 11.9783 5.33136 13.0664 4.55416L13.2122 4.44997C13.3501 4.35148 13.5417 4.45004 13.5417 4.6195V16.3335C13.5417 16.7086 13.6318 17.0626 13.7915 17.3752H5C3.96447 17.3752 3.125 16.5357 3.125 15.5002V4.6195C3.125 4.45004 3.31654 4.35148 3.45442 4.44997L3.6003 4.55416C4.68838 5.33136 6.15325 5.32544 7.23827 4.55042ZM14.7917 16.3335V12.3752H16.875V16.3335C16.875 16.9088 16.4086 17.3752 15.8333 17.3752C15.258 17.3752 14.7917 16.9088 14.7917 16.3335Z" fill="#4F4F4F"/>
                                                </svg>
                                            </div>
                                            <div>
                                                <h5 className="m-0">{formatCurrency(analytics?.total_voucher_count).formatted}</h5>
                                                <p className="m-0">Total No. of Shopping</p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={3} className="mb-2">
                                    <Card className="border-0 shadow-sm bg--success">
                                        <Card.Body className="d-flex align-items-center gap-3 p-3">
                                            <div className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                    <path d="M11.25 8.62516C11.25 8.27999 10.9702 8.00016 10.625 8.00016H5.625C5.27982 8.00016 5 8.27999 5 8.62516C5 8.97034 5.27982 9.25016 5.625 9.25016H10.625C10.9702 9.25016 11.25 8.97034 11.25 8.62516Z" fill="#008000"/>
                                                    <path d="M10.4167 11.1252C10.4167 10.78 10.1368 10.5002 9.79167 10.5002H5.625C5.27982 10.5002 5 10.78 5 11.1252C5 11.4703 5.27982 11.7502 5.625 11.7502H9.79167C10.1368 11.7502 10.4167 11.4703 10.4167 11.1252Z" fill="#008000"/>
                                                    <path d="M10.625 13.0002C10.9702 13.0002 11.25 13.28 11.25 13.6252C11.25 13.9703 10.9702 14.2502 10.625 14.2502H5.625C5.27982 14.2502 5 13.9703 5 13.6252C5 13.28 5.27982 13.0002 5.625 13.0002H10.625Z" fill="#008000"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 18.6252H15.8333C17.099 18.6252 18.125 17.5992 18.125 16.3335V11.7502C18.125 11.405 17.8452 11.1252 17.5 11.1252H14.7917V4.6195C14.7917 3.43333 13.4509 2.74335 12.4857 3.4328L12.3398 3.537C11.6894 4.00157 10.8084 4.00001 10.1549 3.53325C9.06839 2.75714 7.59828 2.75714 6.51173 3.53325C5.85826 4.00001 4.97725 4.00157 4.32685 3.537L4.18097 3.4328C3.21575 2.74335 1.875 3.43333 1.875 4.6195V15.5002C1.875 17.2261 3.27411 18.6252 5 18.6252ZM7.23827 4.55042C7.89021 4.08476 8.77646 4.08476 9.42839 4.55042C10.5134 5.32544 11.9783 5.33136 13.0664 4.55416L13.2122 4.44997C13.3501 4.35148 13.5417 4.45004 13.5417 4.6195V16.3335C13.5417 16.7086 13.6318 17.0626 13.7915 17.3752H5C3.96447 17.3752 3.125 16.5357 3.125 15.5002V4.6195C3.125 4.45004 3.31654 4.35148 3.45442 4.44997L3.6003 4.55416C4.68838 5.33136 6.15325 5.32544 7.23827 4.55042ZM14.7917 16.3335V12.3752H16.875V16.3335C16.875 16.9088 16.4086 17.3752 15.8333 17.3752C15.258 17.3752 14.7917 16.9088 14.7917 16.3335Z" fill="#008000"/>
                                                </svg>
                                            </div>
                                            <div>
                                                <h5 className="m-0">{formatCurrency(analytics?.total_voucher_amount).with_currency}</h5>
                                                <p className="m-0">Total Voucher Amount</p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        )
                    }

                    <RecordFilter
                        handleInput={handleInput}
                        handleSearchSubmit={handleSearch}
                    />

                    <Card className="border-0 shadow-sm">
                        <Card.Body className="table-responsive-lg">
                        <Table size="sm" className="table-color">
                                <thead>
                                    <tr>
                                        <th>Customer</th>
                                        <th>Transaction Type</th>
                                        <th>Amount</th>
                                        <th>Balance Before</th>
                                        <th>Balance After</th>
                                        <th>Fee</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        paycodes?.length ? (
                                            paycodes.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.customer_name}</td>
                                                    <td>{item.transaction_type}</td>
                                                    <td>
                                                        {formatCurrency(item.amount).with_currency}
                                                    </td>
                                                    <td>
                                                        {formatCurrency(item.amount_before).with_currency}
                                                    </td>
                                                    <td>
                                                        {formatCurrency(item.amount_after).with_currency}
                                                    </td>
                                                    <td>
                                                        {formatCurrency(item.fee).with_currency}
                                                    </td>
                                                    <td>{dateFormatter(item.created_at)}</td>
                                                    <td>{item.status}</td>
                                                </tr>
                                            ))
                                        )
                                        :
                                        (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        paycodes?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator
                                    next={paginationData.next}
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </div>
            </Container>
        </Layout>
    );
}

export default LoyaltyVoucherHistory;