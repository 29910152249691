import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";


const WithdrawalForm = (props) => {

    const [validated, setValidated] = useState(false);
    const [payload, setPayload] = useState({amount: null, total_amount: null})

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        setValidated(true);
    };

    const handleInput = (event) => {
        const { name, value } = event.target
        setPayload((prevState)=> ({...prevState,[name]:value}))
        // console.log(payload)
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
                <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                    <Form.Label className="mb-0 text-sm">Amount</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="₦0.00"
                        name="amount"
                        onChange={handleInput}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                    <FontAwesomeIcon icon="fa fa-circle-info"/>
                        <span className="px-1 text-primary">Enter a valid amount</span>
                    </Form.Control.Feedback>
                    <p className="m-0 text-secondary">
                        <small>Maximum Limit: ₦2,000000</small>
                    </p>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                    <Form.Label className="mb-0 text-sm">Total Amount</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="₦0.00"
                        name="total_amount"
                        onChange={handleInput}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                    <FontAwesomeIcon icon="fa fa-circle-info"/>
                        <span className="px-1 text-primary">Enter a vlaid amount</span>
                    </Form.Control.Feedback>
                    <p className="m-0 text-secondary">
                        <small>0.5% fees on each withdrawal capped @ N2500</small>
                    </p>
                </Form.Group>
                <Form.Group>
                    <p>4-Digit Transaction PIN</p>
                    <div className="otp-boxes w-50">
                        <input type="text" className="box" />
                        <input type="text" className="box" />
                        <input type="text" className="box" />
                        <input type="text" className="box" />
                    </div>
                    { props.warning ? (
                        <small className="mt-1 text-muted">No wallet Pin no withdrawal</small>
                    ): null }
                </Form.Group>
            </Row>
            <Form.Group className="mt-4">
                <Button 
                    variant="primary" 
                    className="form-control" 
                    size="lg"
                    onClick={props.onClick}
                >
                    {props.buttonText}
                </Button>
            </Form.Group>
        </Form>
    );
}

export default WithdrawalForm;