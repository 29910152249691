import { useState } from "react";
import { Col, Container, Form, Row, Button, InputGroup, Spinner } from "react-bootstrap";
import Logo from "../../components/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import lock_image from "../../assets/images/landing_page/lock_circle.svg";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../redux/slices/auth";
import Swal from "sweetalert2";

const ResetPassword = () => {

    const [is_active, setIsActive] = useState(false)
    const [payload, setPayload] = useState({})
    const [show_password, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)


    const navigate = useNavigate();

    const dispatch = useDispatch()

    const handleInput = (event) => {
        const { name, value } = event.target
        setPayload((prevState)=> ({...prevState,[name]:value}))
        if(payload.password && payload.email && payload.otp)  {
            setIsActive(true)
        }
        else  {
            setIsActive(false)
        }
    }

    const togglePassword = () => {
        setShowPassword(!show_password)
    }

    const handlePasswordReset = () => {
        setLoading(true)
        dispatch(resetPassword(payload))
        .then((response) => {
            // console.log(response)
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                navigate("/", { replace: true })
            }
            else if(response.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    return (
        <Row className="full-height justify-content-center">
            <Col md={5}>
                <Container className="mt-3 text-center px-5">
                    <div className="mt-3 mb-5">
                        <Logo variant="dark"/>
                    </div>
                </Container>
                <Container className="mt-5 text-center pt-5">
                    <div >
                        <img src={lock_image} alt="reset password" width={70} />
                    </div>
                    <h3 className="text-primary mt-2">Reset your Password</h3>
                    <p className="text-dark">
                        Set your password to access your account.
                    </p>
                </Container>
                <Container className="px-5 mt-5">
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="validationCustomUsername" className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    size="lg"
                                    name="email"
                                    onChange={handleInput}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustomUsername" className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type={show_password ? "text" : "password"}
                                        placeholder="Password"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        size="lg"
                                        name="password"
                                        onChange={handleInput}
                                    />
                                    <InputGroup.Text id="inputGroupPrepend" onClick={togglePassword}>
                                        <Button type="button" variant="light" className="border-0 input-group-btn-bg">
                                            <FontAwesomeIcon icon={!show_password ? "far fa-eye-slash" : "far fa-eye"} />
                                        </Button>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustomUsername" className="mb-3">
                                <Form.Label>OTP</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="OTP"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    size="lg"
                                    name="otp"
                                    onChange={handleInput}
                                    maxLength={6}
                                    minLength={6}
                                />
                            </Form.Group>
                        </Row>
                        <div className="d-grid gap-3">
                            <Button 
                                type="button" 
                                size="lg" 
                                disabled={!is_active ? true : false}
                                onClick={handlePasswordReset}
                            >
                                Set Password <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                            </Button>
                        </div>
                    </Form>
                </Container>
            </Col>
        </Row>
    );
}


export default ResetPassword;