import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV });
// const axiosInstance = axios.create({ baseURL: process.env.BASE_URL || 'https://business.wallx.co' });


axiosInstance.interceptors.request.use(function (req) {
  const accessToken = window.localStorage.getItem('tkn')
  if (accessToken) {
    req.headers.authorization = `Token ${accessToken}`
  }
  return req;
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('tkn')
      let current = localStorage.getItem("redux-auth");
      // console.log("USER LOGUT ", current)
      let authLog = JSON.parse(current)
      authLog.isLoggedIn = false
      localStorage.setItem("redux-auth", JSON.stringify(authLog));
      window.location.replace('/')
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);


export default axiosInstance;
