import React, { useState } from 'react';

const AmountInput = ({inputValue, onChange}) => {
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    let input = e.target.value;

    input = input.replace(/[^\d.]/g, '');

    // Allow only one decimal point
    const parts = input.split('.');
    if (parts.length > 2) {
      return; 
    }

    // Format integer part with comma separator
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    setValue(parts.join('.'));
    inputValue = parts.join('.')
  };

  const handleBlur = () => {
    // If input ends with a decimal point, remove it
    setValue((prevValue) => prevValue.replace(/\.$/, ''));
    onChange(value.replace(/\.$/, ''));
    inputValue = value
  };

  return (
    <div>
      <input
        type="text"
        id="amount"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Enter amount"
        className="form-control"
      />
    </div>
  );
};

export default AmountInput;
