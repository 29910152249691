import { Button } from "react-bootstrap";

const ServiceBox = (props) => {
    const { children } = props;

    return (
        <div className="service-card">
            <div className="icon mt-3 mb-3 text-center">
                {children}
            </div>
            {
                props.text ?  (
                    <p>{props.text}</p>
                ) : null
            }
            { 
                props.buttonText ? (
                    <Button 
                        type="button" 
                        variant="primary"
                        className="mt-3"
                        onClick={props.onClick || null}
                    >
                        {props.buttonText}
                    </Button>
                ): null
            }
        </div>
    );
}


export default ServiceBox;