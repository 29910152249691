import ServiceHelper from "./service.helper";

export default class AccountService {
    static BASE_URL = '/auth';

    static createVirtualAccount(payload) {
        return ServiceHelper.handlePostRequest(`/virtual_account/`, payload);
    }

    static getVirtualAccounts() {
        return ServiceHelper.handleGetRequest('/virtual_account')
    }

    static getAccounts() {
        return ServiceHelper.handleGetRequest('/accounts')
    }

    static getBankList() {
        return ServiceHelper.handleGetRequest(`/add_bank/?action=get_banks`);
    }

    static getCountries() {
        return ServiceHelper.handleGetRequest(`/country_list/`);
    }

    static verifyBvn(bvn, dob) {
        return ServiceHelper.handleGetRequest(`/verify_bvn/?bvn=${bvn}&dob=${dob}`);
    }
    
    static validateAccountNumber(payload) {
        return ServiceHelper.handleGetRequest(`/add_bank/?action=validate&code=${payload.code}&account_number=${payload.account_number}`);
    }
    
    static createPaycode(payload){
        return ServiceHelper.handlePostRequest('/create_paycode/', payload);
    }
    
    
    static createQrPaycode(payload){
        delete payload['merchant_id']
        return ServiceHelper.handlePostRequest(`/create_paycode/${payload.merchant_id}`, payload);
    }
    
    static bulkCreatePaycode(payload){
        return ServiceHelper.handlePostRequest('/create_bulk_paycode/', payload);
    }

    static checkPaycodeBalance(payload) {
        return ServiceHelper.handleGetRequest(`/paycode_balance/?token=${payload.paycode}&secret=${payload.secret_word}`);
    }
     
    static confirmPaycode(payload){
        return ServiceHelper.handlePostRequest('/confirm_paycode/', payload);
    }

    // static getMerchantPaycode(params){
    //     return ServiceHelper.handleGetRequest(`/paycodes/?page=${param}`);
    // }

    static getMerchantPaycode(params) {
        let request_params = ``;

        if(params.from_date !== '' && params.from_date !== undefined) {
            request_params += `&from_date=${params.from_date}`; 
        }
        if(params.to_date !== '' && params.to_date !== undefined){
            request_params += `&to_date=${params.to_date}`;
        }
        if(params.status !== '' && params.status !== undefined) {
            request_params += `&status=${params.status}`;
        }
        if(params.filter !== '' && params.filter !== undefined) {
            request_params += `&filter=${params.filter}`;
        }
        if(params.filter_by !== '' && params.filter_by !== undefined) {
            request_params += `&filter_by=${params.filter_by}`;
        }
        if(params.sort_by !== '' && params.sort_by !== undefined) {
            request_params += `&sort_by=${params.sort_by}`;
        }
        if(params.download !== '' && params.download !== undefined && params.download == 'True') {
            request_params += `&download=${params.download}`;
        }
        if(params.gateway !== '' && params.gateway !== undefined) {
            request_params += `&gateway=${params.gateway}`;
        }
        if(params.page !== '' && params.page !== undefined) {
            request_params += `&page=${params.page}`;
        }
        request_params = request_params.slice(1)
        return ServiceHelper.handleGetRequest(`/paycodes/?${request_params}`);
    }

    static claimPaycode(payload){
        return ServiceHelper.handlePostRequest('/claim_paycode/', payload);
    }

    static claimPaycodeWithQr(payload){
        return ServiceHelper.handlePostRequest('/api-v1/claim_paycode/', payload);
    }

    static applyPaycode(payload){
        return ServiceHelper.handlePostRequest('/paycode/redeem/', payload);
    }

    static addAccountDetails(payload) {
        return ServiceHelper.handlePostRequest('/add_bank/', payload);
    }

    static transactionHistory(params) {
        let request_params = ``;

        if(params.from_date !== '' && params.from_date !== undefined) {
            request_params += `&from_date=${params.from_date}`; 
        }
        if(params.to_date !== '' && params.to_date !== undefined){
            request_params += `&to_date=${params.to_date}`;
        }
        if(params.filter_by === 'type'){
            request_params += `&transaction_type=${params.filter}`;
        }
        if(params.status !== '' && params.status !== undefined) {
            request_params += `&status=${params.status}`;
        }
        if(params.filter !== '' && params.filter !== undefined) {
            request_params += `&filter=${params.filter}`;
        }
        if(params.filter_by !== '' && params.filter_by !== undefined) {
            request_params += `&filter_by=${params.filter_by}`;
        }
        if(params.sort_by !== '' && params.sort_by !== undefined) {
            request_params += `&sort_by=${params.sort_by}`;
        }
        if(params.download !== '' && params.download !== undefined && params.download == 'True') {
            request_params += `&download=${params.download}`;
        }
        if(params.gateway !== '' && params.gateway !== undefined) {
            request_params += `&gateway=${params.gateway}`;
        }
        if(params.page !== '' && params.page !== undefined) {
            request_params += `&page=${params.page}`;
        }
        request_params = request_params.slice(1)
        return ServiceHelper.handleGetRequest(`/transaction_history/?${request_params}`);
    }

    static generateReport(params) {
        let request_params = ``;

        if(params.from_date != '') {
            request_params += `&from_date=${params.from_date}`; 
        }
        if(params.to_date != ''){
            request_params += `&to_date=${params.to_date}`;
        }
        if(params.status != '' && params.status != 'all') {
            request_params += `&status=${params.status}`;
        }
        if(params.transaction_type != '' && params.transaction_type != 'all') {
            request_params += `&transaction_type=${params.transaction_type}`;
        }
        if(params.reference != '') {
            request_params += `&reference=${params.reference}`;
        }
        if(params.download != '' && params.download == 'True') {
            request_params += `&download=${params.download}`;
        }
        if(params.page != '') {
            request_params += `&page=${params.page}`;
        }
        if(params.gateway != '') {
            request_params += `&gateway=${params.gateway}`;
        }
        request_params = request_params.slice(1)
        if(params.download == 'True') {
            return ServiceHelper.handleDownloadRequest(`/transaction_history/?${request_params}`);
        }
        return ServiceHelper.handleGetRequest(`/transaction_history/?${request_params}`);
    }

    static getInvoices(param = 1) {
        return ServiceHelper.handleGetRequest(`/merchant_invoice/?page=${param}`);
    }

    static getInvoicesByRef(ref) {
        return ServiceHelper.handleGetRequest(`/merchant_invoice/?ref=${ref}`);
    }
    
    static createInvoice(payload) {
        return ServiceHelper.handlePostRequest(`/merchant_invoice/`, payload);
    }
    
    static invoicePayment(payload) {
        return ServiceHelper.handlePostRequest(`/pay_invoice/${payload.invoice_id}/`, payload);
    }

    static getVouchers() {
        return ServiceHelper.handleGetRequest(`/user_vouchers/`);
    }

    static getCharges() {
        return ServiceHelper.handleGetRequest("/transaction_charges/");
      }
    
    static analytics(params) {
        let url = "/dashboard_analytics/"
        if(params.type !== 'day') {
            url = "/dashboard_analytics/?type="+params.type
        }
        else if(params.type === 'day' && params.date !== undefined && params.date !== undefined) { 
            url = `/dashboard_analytics/?type=${params.type}&date=${params.date}`
        }
        return ServiceHelper.handleGetRequest(url);
    }
    
    static verifyInvoice(payload) {
        return ServiceHelper.handlePostRequest("/verify_invoice/", payload);
    }
    
    static announcements() {
        return ServiceHelper.handleGetRequest("/announcements/");
    }
    
    static uploadContactList() {
        return ServiceHelper.handlePostRequest("/contact_upload/");
    }

    static fasePay(payload){
        return ServiceHelper.handlePostRequest('/fase_pay/', payload);
    } 

    static sellFx(payload){
        return ServiceHelper.handlePostRequest('/sell_fx/', payload);
    }

    static fasepayTransactions(params = 1) {
        return ServiceHelper.handleGetRequest(`/transaction_history/?gateway=FasePay&page=${params}`);
    }
    
    static getFxRates(){
        return ServiceHelper.handleGetRequest('/sell_fx/');
    }
    
    static chargeAccount(payload){
        return ServiceHelper.handlePostRequest('/api-v1/side_brief_webhook/', payload);
    }

    static getInflowData(){
        return ServiceHelper.handleGetRequest('/inflow_data/');
    }

    static getTaxCalculators(params) {
        let uri =  `/tax`;
        if(params.filter_year !== '' && params.filter_year !== undefined && params.filter_month !== '' && params.filter_month !== undefined) {
            uri = `/tax/?filter_year=${params.filter_year}&filter_month=${params.filter_month}`;
        }
        return ServiceHelper.handleGetRequest(`${uri}`);
    }

    static createLoyaltyPaycode(payload){
        return ServiceHelper.handlePostRequest('/loyalty_voucher/', payload);
    }
    
    static createLoyaltyQrPaycode(payload){
        delete payload['merchant_id']
        return ServiceHelper.handlePostRequest(`/loyalty_voucher/${payload.merchant_id}`, payload);
    }

    static checkLoyaltyPaycodeBalance(payload) {
        if(payload.user_type === 'owner') {
            return ServiceHelper.handleGetRequest(`/loyalty_voucher_balance/?code=${payload.paycode}&secret=${payload.secret_word}`);
        }
        return ServiceHelper.handleGetRequest(`/members/loyalty_voucher_balance/?code=${payload.paycode}&secret=${payload.secret_word}`);
    }
     
    static confirmLoyaltyPaycode(payload){
        return ServiceHelper.handlePostRequest('/confirm_paycode/', payload);
    }

    static claimLoyaltyPaycode(payload){
        return ServiceHelper.handlePutRequest('/loyalty_voucher/', payload);
    }

    // static getLoyaltyPaycodes(payload){
    //     return ServiceHelper.handleGetRequest('/loyalty_voucher/', payload);
    // }

    static getLoyaltyPaycodes(params) {
        let request_params = ``;

        if(params.from_date !== '' && params.from_date !== undefined) {
            request_params += `&from_date=${params.from_date}`; 
        }
        if(params.to_date !== '' && params.to_date !== undefined){
            request_params += `&to_date=${params.to_date}`;
        }
        if(params.page !== '' && params.page !== undefined) {
            request_params += `&page=${params.page}`;
        }
        if(params.filter_by !== '' && params.filter_by !== undefined && params.from_date === '') {
            request_params += `&filter_by=${params.filter}`;
        }
        if(params.paycode_id !== '' && params.paycode_id !== undefined) {
            request_params += `&paycode_id=${params.paycode_id}`;
        }
        request_params = request_params.slice(1)
        if(params.user_type === 'owner') {
            return ServiceHelper.handleGetRequest(`/loyalty_voucher/?${request_params}`);
        }
        return ServiceHelper.handleGetRequest(`/members/loyalty_voucher/?${request_params}`);
    }

    static getLoyaltyPaycodeHistory(params) {
        let request_params = ``;

        if(params.from_date !== '' && params.from_date !== undefined) {
            request_params += `&from_date=${params.from_date}`; 
        }
        if(params.to_date !== '' && params.to_date !== undefined){
            request_params += `&to_date=${params.to_date}`;
        }
        if(params.page !== '' && params.page !== undefined) {
            request_params += `&page=${params.page}`;
        }
        if(params.filter_by !== '' && params.filter_by !== undefined && params.from_date === '') {
            request_params += `&filter_by=${params.filter}`;
        }
        if(params.paycode_id !== '' && params.paycode_id !== undefined) {
            request_params += `&paycode_id=${params.paycode_id}`;
        }
        request_params = request_params.slice(1)
        if(params.user_type === 'owner') {
            return ServiceHelper.handleGetRequest(`/loyalty_voucher_history/?${request_params}`);
        }
        return ServiceHelper.handleGetRequest(`/members/loyalty_voucher_history/?${request_params}`);
    }

    static sendOtp(){
        return ServiceHelper.handleGetRequest('/loyalty_voucher_verification/');
    }

}