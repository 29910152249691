import { Row, Col, Container, Card, Button, Table, Dropdown, FormCheck, Form, Collapse, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import DrewsModal from "../../components/modal/DrewsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { deleteEmailById, getEmailList, sendEmail, getCustomersList } from "../../redux/slices/users";
import Swal from "sweetalert2";
import { contactlistUpload } from "../../redux/slices/account.service";
import Select from 'react-select';


const Messaging = () => {

    const dispatch = useDispatch()

    const [withdrawal, showForm] = useState(false)
    const [payload, setPayload] = useState({emails: []})
    const [isActive, setActive] = useState(1)
    const [data, setData] = useState([])
    const [page, setPage] = useState('all')
    const [loading, setLoading] = useState(false)
    const [sentMessages, setSentMessages] = useState([])
    const [customers, setCustomers] = useState([])
    const [contactlist, setContactList] = useState(null)

    const email_contacts = customers.map((customer) => {
        return {label: `${customer.full_name} - ${customer.email}`, value: customer.email}
    })

    useEffect(() => {
        loadEmailData()
        loadCustomers()
    }, [0])

    const loadEmailData = () => {
        dispatch(getEmailList())
        .then((response) => {
            if(response.payload?.results) {
                let results = response.payload.results.filter(item => item.sent === true)
                let inbox = response.payload.results.filter(item => item.sent !== true)
                setData(inbox)
                setSentMessages(results)
            }
        })
    }

    const handleFormInput = (event) => {
        const { name, value } = event.target
        setPayload({...payload, [name]:value})
    }

    const sendMessage = () => {
       setLoading(true)
       dispatch(sendEmail(payload))
       .then((res) => {
            if(res.payload?.success && res.payload?.success == true) {
                loadEmailData()
                Swal.fire({
                    icon: 'success',
                    text: res.payload?.message || 'Message sent'
                })
                .then(() => {
                    showForm(false)
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: res.payload?.message || 'Could not send message'
                })
            }
        })
       .finally(() => setLoading(false))
    }

    const confirmDelete = (id) => {
       setLoading(true)
       dispatch(deleteEmailById(id))
       .then((res) => {
            if(res.payload?.success && res.payload?.success == true) {
                loadEmailData()
                Swal.fire({
                    icon: 'success',
                    text: res.payload?.message || 'Message deleted'
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: res.payload?.message || 'Could not delete message'
                })
            }
        })
       .finally(() => setLoading(false))
    }

    const handleDelete = (message_id) => {
        Swal.fire({
            icon: 'warning',
            text: 'Are you sure you want to delete this?',
            showConfirmButton: true,
            confirmButtonText: "Yes, Proceed",
            showCancelButton: true,
          }).then((result) => {
              if (result.isConfirmed){
                confirmDelete(message_id);
              }
          });
    }

    const handleMailsending = () => {
        Swal.fire({
            icon: 'warning',
            text: 'Are you about sending out an email, would you like to proceed?',
            showConfirmButton: true,
            confirmButtonText: "Yes, Send",
            showCancelButton: true,
          }).then((result) => {
              if (result.isConfirmed){
                sendMessage();
              }
          });
    }

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            setContactList(file)
        };
    }

    const loadCustomers = () => {
        dispatch(getCustomersList())
        .then((res) => {
            if(res.payload?.results) {
                setCustomers(res.payload?.results)
            }
        })
    }

    const uploadContacts = () => {
        let formdata = new FormData()
        formdata.append('contact_list', contactlist)
        dispatch(contactlistUpload(formdata))
        .then((res) => {
            if(res.payload?.status && res.payload?.status == true) {
                Swal.fire({
                    icon: 'success',
                    text: res.payload?.message || 'Contacts uploaded'
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: res.payload?.message || 'Could not upload contacts'
                })
            }
        })
    }

    const handleSelect = (event) => {
        let contacts = event.map((contact) => {
            return contact.value
        })
        setPayload({...payload, emails: contacts})
        // console.log("COntacts", payload)
    }

    return (
        <Layout>
            <>
                <Row>
                    <Col md={3} className="bg-white full-height">
                        <Container className="p-4 py-5">
                            <h3>My mails</h3>
                            <div className="mt-3 mb-3">
                                <Button 
                                    type="button" 
                                    variant="primary" 
                                    className="mb-5"
                                    onClick={ () => showForm(true) }
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M11 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V12.5M10.519 13.8946L11.8338 13.6347C12.5433 13.4945 12.898 13.4244 13.2288 13.2953C13.5224 13.1807 13.8014 13.0318 14.0601 12.8516C14.3515 12.6487 14.6072 12.393 15.1185 11.8816L20.267 6.73321C20.9542 6.04596 20.9542 4.9317 20.267 4.24444C19.5797 3.55719 18.4654 3.55719 17.7782 4.24445L12.5418 9.48088C12.0626 9.96004 11.823 10.1996 11.6295 10.4712C11.4577 10.7123 11.3132 10.9717 11.1987 11.2447C11.0697 11.5522 10.9922 11.8821 10.8371 12.5417L10.519 13.8946Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <span className="px-2">Compose a mail</span>
                                </Button>
                                <nav>
                                    <ul className="p-0">
                                        <li className="nav-item d-flex align-items-center gap-3 mb-4 mt-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M3.02878 8C3.02878 8 8.33702 11.3619 11 12C11.4596 12.1101 12.5404 12.1101 13 12C15.7647 11.3375 20.9712 8 20.9712 8M7.8 19H16.2C17.8802 19 18.7202 19 19.362 18.673C19.9265 18.3854 20.3854 17.9265 20.673 17.362C21 16.7202 21 15.8802 21 14.2V9.8C21 8.11984 21 7.27976 20.673 6.63803C20.3854 6.07354 19.9265 5.6146 19.362 5.32698C18.7202 5 17.8802 5 16.2 5H7.8C6.11984 5 5.27976 5 4.63803 5.32698C4.07354 5.6146 3.6146 6.07354 3.32698 6.63803C3 7.27976 3 8.11984 3 9.8V14.2C3 15.8802 3 16.7202 3.32698 17.362C3.6146 17.9265 4.07354 18.3854 4.63803 18.673C5.27976 19 6.11984 19 7.8 19Z" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <Button
                                                type="button" 
                                                size="sm" 
                                                variant="light" 
                                                className="nav-link px-2" 
                                                style={{ color: "#828282" }}
                                                onClick={ () => setPage('all') }
                                            >
                                                All mails
                                            </Button>
                                        </li>
                                        <li className="nav-item d-flex align-items-center gap-3 mb-4 mt-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M3.02868 10.0001L10.225 14.8166C10.8665 15.2443 11.1872 15.4582 11.5339 15.5412C11.8403 15.6147 12.1597 15.6147 12.4661 15.5412C12.8128 15.4582 13.1335 15.2443 13.775 14.8166L20.9713 10.0001M10 9.66667L11.3846 11L14.5 8M9.4472 4.60319L5.2472 7.24084C4.42682 7.75606 4.01662 8.01366 3.7191 8.3619C3.45577 8.67012 3.25764 9.02851 3.13667 9.41544C3 9.8526 3 10.337 3 11.3057V15.2C3 16.8802 3 17.7202 3.32698 18.362C3.6146 18.9265 4.07354 19.3854 4.63803 19.673C5.27976 20 6.11984 20 7.8 20H16.2C17.8802 20 18.7202 20 19.362 19.673C19.9265 19.3854 20.3854 18.9265 20.673 18.362C21 17.7202 21 16.8802 21 15.2V11.3057C21 10.337 21 9.8526 20.8633 9.41544C20.7424 9.02851 20.5442 8.67012 20.2809 8.3619C19.9834 8.01366 19.5732 7.75606 18.7528 7.24084L14.5528 4.60319C13.6269 4.02175 13.164 3.73102 12.6673 3.61769C12.2281 3.51749 11.7719 3.51749 11.3327 3.61769C10.836 3.73102 10.3731 4.02175 9.4472 4.60319Z" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <Button
                                                type="button" 
                                                size="sm" 
                                                variant="light" 
                                                className="nav-link px-2" 
                                                style={{ color: "#828282" }}
                                                onClick={ () => setPage('sent') }
                                            >
                                                Sent Mails
                                            </Button>
                                        </li>
                                        <li className="nav-item d-flex align-items-center gap-3 mb-4 mt-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M18 12V8.2C18 7.07989 18 6.51984 17.782 6.09202C17.5903 5.71569 17.2843 5.40973 16.908 5.21799C16.4802 5 15.9201 5 14.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.0799 3 8.2V16.8C3 17.9201 3 18.4802 3.21799 18.908C3.40973 19.2843 3.71569 19.5903 4.09202 19.782C4.51984 20 5.0799 20 6.2 20H13.5M3 9H18M5 3V5M16 3V5M15 16.5H18M16.5 15V18M21 16.5C21 18.9853 18.9853 21 16.5 21C14.0147 21 12 18.9853 12 16.5C12 14.0147 14.0147 12 16.5 12C18.9853 12 21 14.0147 21 16.5Z" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <Button
                                                type="button" 
                                                size="sm" 
                                                variant="light" 
                                                className="nav-link px-2" 
                                                style={{ color: "#828282" }}
                                                onClick={ () => setPage('scheduled') }
                                            >
                                                Scheduled Mails
                                            </Button>
                                        </li>
                                        <li className="nav-item d-flex align-items-center gap-3 mb-4 mt-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M20.8351 14H18.2C17.0799 14 16.5198 14 16.092 14.218C15.7157 14.4097 15.4097 14.7157 15.218 15.092C15 15.5198 15 16.0799 15 17.2V19.9288M21 12.7277V7.2C21 6.0799 21 5.51984 20.782 5.09202C20.5903 4.71569 20.2843 4.40973 19.908 4.21799C19.4802 4 18.9201 4 17.8 4H7.2C6.0799 4 5.51984 4 5.09202 4.21799C4.71569 4.40973 4.40973 4.71569 4.21799 5.09202C4 5.51984 4 6.0799 4 7.2V16.8C4 17.9201 4 18.4802 4.21799 18.908C4.40973 19.2843 4.71569 19.5903 5.09202 19.782C5.51984 20 6.0799 20 7.2 20H13.954C14.4624 20 14.7166 20 14.9548 19.9407C15.1659 19.8881 15.367 19.8014 15.5501 19.684C15.7568 19.5517 15.9313 19.3668 16.2804 18.9972L20.1264 14.9249C20.4492 14.5832 20.6106 14.4123 20.7258 14.2155C20.828 14.041 20.9032 13.8521 20.9487 13.6551C21 13.4329 21 13.1978 21 12.7277Z" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <Button
                                                type="button" 
                                                size="sm" 
                                                variant="light" 
                                                className="nav-link px-2" 
                                                style={{ color: "#828282" }}
                                                onClick={ () => setPage('draft') }
                                            >
                                                Draft Mails
                                            </Button>
                                        </li>
                                        <li className="nav-item d-flex align-items-center gap-3 mb-4 mt-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M20 10V8.8C20 7.11984 20 6.27976 19.673 5.63803C19.3854 5.07354 18.9265 4.6146 18.362 4.32698C17.7202 4 16.8802 4 15.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V12.2C3 13.8802 3 14.7202 3.32698 15.362C3.6146 15.9265 4.07354 16.3854 4.63803 16.673C5.27976 17 6.11984 17 7.8 17H10M3.36 5.58L9.50097 10.4008C10.2144 10.9715 10.5711 11.2569 10.9667 11.3663C11.3157 11.4629 11.6843 11.4629 12.0333 11.3663C12.4289 11.2569 12.7856 10.9715 13.499 10.4008L19.64 5.58M18 13.5L19.4107 15.5584L21.8042 16.2639L20.2825 18.2416L20.3511 20.7361L18 19.9L15.6489 20.7361L15.7175 18.2416L14.1958 16.2639L16.5893 15.5584L18 13.5Z" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <Button
                                                type="button" 
                                                size="sm" 
                                                variant="light" 
                                                className="nav-link px-2" 
                                                style={{ color: "#828282" }}
                                                onClick={ () => setPage('starred') }
                                            >
                                                Starred Mails
                                            </Button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </Container>
                    </Col>
                    <Col md={9}>
                        <Container fluid className="mt-5">
                            <Collapse in={page === 'all'}>
                                <div>
                                    <div className="d-flex gap-2">
                                        <Button 
                                            type="button" 
                                            variant="light" 
                                            className={isActive === 1 ? "bg-white text-primary border-0" : "text-muted border-0"}
                                            onClick={ () => setActive(1) }
                                        >
                                            Broadcast mails
                                        </Button>
                                        <Button 
                                            type="button" 
                                            variant="light" 
                                            className={isActive === 2 ? "bg-white text-primary border-0" : "text-muted border-0"}
                                            onClick={ () => setActive(2) }
                                        >
                                            Personalized mails
                                        </Button>
                                    </div>
                                    <Card className="border-0 bg-transparent">
                                        <Card.Body className="p-4m mailing-list bg-transparent table-responsive-lg">
                                            <Table size="sm" className="table-color">
                                                <tbody>
                                                    {
                                                        data?.length ? (
                                                            data?.map((item, index) => (
                                                                <tr key={index} className="border-bottom">
                                                                    <td className="bg-transparent">
                                                                        <div className="m-0 d-flex gap-2 align-items-center">
                                                                            <FormCheck type="checkbox"/>
                                                                            <img src={item.avatar || 'https://via.placeholder.com/50'} alt={item.message} width="45" className="rounded-circle"/>
                                                                            {/* <strong>
                                                                                {item.name}
                                                                            </strong> */}
                                                                        </div>
                                                                    </td>
                                                                    <td className="bg-transparent">
                                                                        {/* <span>{item.title}</span> -  */}
                                                                        <strong>{item.message}</strong>
                                                                    </td>
                                                                    <td className="bg-transparent">
                                                                        <div className="d-flex m-0 align-items-center justify-content-between">
                                                                            {item.date}
                                                                            <Dropdown>
                                                                                <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                                    <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                                </Dropdown.Toggle>
    
                                                                                <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                                    {/* <Dropdown.Item 
                                                                                        eventKey={1} 
                                                                                        className="text-primary d-flex align-items-center gap-1" 
                                                                                        onClick={ () => showForm(true) }
                                                                                    >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                        <path d="M3 14C3 9.02944 7.02944 5 12 5C16.9706 5 21 9.02944 21 14M17 14C17 16.7614 14.7614 19 12 19C9.23858 19 7 16.7614 7 14C7 11.2386 9.23858 9 12 9C14.7614 9 17 11.2386 17 14Z" stroke="#443792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                    </svg> 
                                                                                    View Mail
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Divider /> */}
                                                                                    <Dropdown.Item 
                                                                                        eventKey={2} 
                                                                                        className="d-flex align-items-center gap-1 text-danger"
                                                                                        onClick={ () => handleDelete(item.id) }
                                                                                    >
                                                                                        <FontAwesomeIcon icon="trash" />
                                                                                        Delete
                                                                                    </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        )
                                                        :
                                                        (
                                                            <tr>
                                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Collapse>
                            <Collapse in={page === 'sent'}>
                                <Card className="border-0 bg-transparent">
                                    <Card.Body className="mailing-list bg-transparent table-responsive-lg">
                                        <h5>Sent Messages</h5>
                                        <Table size="sm" className="table-color">
                                            <tbody>
                                                {
                                                    sentMessages?.length ? (
                                                        sentMessages?.map((item, index) => (
                                                            <tr key={index} className="border-bottom">
                                                                <td className="bg-transparent">
                                                                    <div className="m-0 d-flex gap-2 align-items-center">
                                                                        <FormCheck type="checkbox"/>
                                                                        <img src={item.avatar || 'https://via.placeholder.com/50'} alt={item.message} width="45" className="rounded-circle"/>
                                                                        <strong>
                                                                            {item.name}
                                                                        </strong>
                                                                    </div>
                                                                </td>
                                                                <td className="bg-transparent">
                                                                    <span>{item.title}</span> - <strong>{item.message}</strong>
                                                                </td>
                                                                <td className="bg-transparent">
                                                                    <div className="d-flex m-0 align-items-center justify-content-between">
                                                                        {item.date}
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                                <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                            </Dropdown.Toggle>
    
                                                                            <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                                {/* <Dropdown.Item 
                                                                                    eventKey={1} 
                                                                                    className="text-primary d-flex align-items-center gap-1" 
                                                                                    onClick={ () => showForm(true) }
                                                                                >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                    <path d="M3 14C3 9.02944 7.02944 5 12 5C16.9706 5 21 9.02944 21 14M17 14C17 16.7614 14.7614 19 12 19C9.23858 19 7 16.7614 7 14C7 11.2386 9.23858 9 12 9C14.7614 9 17 11.2386 17 14Z" stroke="#443792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                </svg> 
                                                                                View Mail
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Divider /> */}
                                                                                <Dropdown.Item 
                                                                                    eventKey={2} 
                                                                                    className="d-flex align-items-center gap-1 text-danger"
                                                                                    onClick={ () => handleDelete(item.id) }
                                                                                >
                                                                                    <FontAwesomeIcon icon="trash" /> Delete
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) 
                                                    : 
                                                    (
                                                        <tr>
                                                            <td colSpan={5} className="text-center text-muted">No data found</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Collapse>
                            <Collapse in={page === 'scheduled'}>
                                <Card className="border-0 bg-transparent">
                                    <Card.Body className="mailing-list bg-transparent table-responsive-lg">
                                        <h5>Scheduled Messages</h5>
                                        <Table size="sm" className="table-color">
                                            <tbody>
                                                {
                                                    sentMessages?.length ? (
                                                        sentMessages?.map((item, index) => (
                                                            <tr key={index} className="border-bottom">
                                                                <td className="bg-transparent">
                                                                    <div className="m-0 d-flex gap-2 align-items-center">
                                                                        <FormCheck type="checkbox"/>
                                                                        <img src={item.avatar || 'https://via.placeholder.com/50'} alt={item.message} width="45" className="rounded-circle"/>
                                                                        <strong>
                                                                            {item.name}
                                                                        </strong>
                                                                    </div>
                                                                </td>
                                                                <td className="bg-transparent">
                                                                    <span>{item.title}</span> - <strong>{item.message}</strong>
                                                                </td>
                                                                <td className="bg-transparent">
                                                                    <div className="d-flex m-0 align-items-center justify-content-between">
                                                                        {item.date}
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                                <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                            </Dropdown.Toggle>
    
                                                                            <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                                {/* <Dropdown.Item 
                                                                                    eventKey={1} 
                                                                                    className="text-primary d-flex align-items-center gap-1" 
                                                                                    onClick={ () => showForm(true) }
                                                                                >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                    <path d="M3 14C3 9.02944 7.02944 5 12 5C16.9706 5 21 9.02944 21 14M17 14C17 16.7614 14.7614 19 12 19C9.23858 19 7 16.7614 7 14C7 11.2386 9.23858 9 12 9C14.7614 9 17 11.2386 17 14Z" stroke="#443792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                </svg> 
                                                                                View Mail
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Divider /> */}
                                                                                <Dropdown.Item 
                                                                                    eventKey={2} 
                                                                                    className="d-flex align-items-center gap-1 text-danger"
                                                                                    onClick={ () => handleDelete(item.id) }
                                                                                >
                                                                                    <FontAwesomeIcon icon="trash" /> Delete
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) 
                                                    : 
                                                    (
                                                        <tr>
                                                            <td colSpan={5} className="text-center text-muted">No data found</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Collapse>
                            <Collapse in={page === 'draft'}>
                                <Card className="border-0 bg-transparent">
                                    <Card.Body className="mailing-list bg-transparent table-responsive-lg">
                                        <h5>Draft Messages</h5>
                                        <Table size="sm" className="table-color">
                                            <tbody>
                                                {
                                                    sentMessages?.length ? (
                                                        sentMessages?.map((item, index) => (
                                                            <tr key={index} className="border-bottom">
                                                                <td className="bg-transparent">
                                                                    <div className="m-0 d-flex gap-2 align-items-center">
                                                                        <FormCheck type="checkbox"/>
                                                                        <img src={item.avatar || 'https://via.placeholder.com/50'} alt={item.message} width="45" className="rounded-circle"/>
                                                                        <strong>
                                                                            {item.name}
                                                                        </strong>
                                                                    </div>
                                                                </td>
                                                                <td className="bg-transparent">
                                                                    <span>{item.title}</span> - <strong>{item.message}</strong>
                                                                </td>
                                                                <td className="bg-transparent">
                                                                    <div className="d-flex m-0 align-items-center justify-content-between">
                                                                        {item.date}
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                                <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                            </Dropdown.Toggle>
    
                                                                            <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                                {/* <Dropdown.Item 
                                                                                    eventKey={1} 
                                                                                    className="text-primary d-flex align-items-center gap-1" 
                                                                                    onClick={ () => showForm(true) }
                                                                                >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                    <path d="M3 14C3 9.02944 7.02944 5 12 5C16.9706 5 21 9.02944 21 14M17 14C17 16.7614 14.7614 19 12 19C9.23858 19 7 16.7614 7 14C7 11.2386 9.23858 9 12 9C14.7614 9 17 11.2386 17 14Z" stroke="#443792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                </svg> 
                                                                                View Mail
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Divider /> */}
                                                                                <Dropdown.Item 
                                                                                    eventKey={2} 
                                                                                    className="d-flex align-items-center gap-1 text-danger"
                                                                                    onClick={ () => handleDelete(item.id) }
                                                                                >
                                                                                    <FontAwesomeIcon icon="trash" /> Delete
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) 
                                                    : 
                                                    (
                                                        <tr>
                                                            <td colSpan={5} className="text-center text-muted">No data found</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Collapse>
                            <Collapse in={page === 'starred'}>
                                <Card className="border-0 bg-transparent">
                                    <Card.Body className="mailing-list bg-transparent table-responsive-lg">
                                        <h5>Starred Messages</h5>
                                        <Table size="sm" className="table-color">
                                            <tbody>
                                                {
                                                    sentMessages?.length ? (
                                                        sentMessages?.map((item, index) => (
                                                            <tr key={index} className="border-bottom">
                                                                <td className="bg-transparent">
                                                                    <div className="m-0 d-flex gap-2 align-items-center">
                                                                        <FormCheck type="checkbox"/>
                                                                        <img src={item.avatar || 'https://via.placeholder.com/50'} alt={item.message} width="45" className="rounded-circle"/>
                                                                        <strong>
                                                                            {item.name}
                                                                        </strong>
                                                                    </div>
                                                                </td>
                                                                <td className="bg-transparent">
                                                                    <span>{item.title}</span> - <strong>{item.message}</strong>
                                                                </td>
                                                                <td className="bg-transparent">
                                                                    <div className="d-flex m-0 align-items-center justify-content-between">
                                                                        {item.date}
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                                <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                            </Dropdown.Toggle>
    
                                                                            <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                                {/* <Dropdown.Item 
                                                                                    eventKey={1} 
                                                                                    className="text-primary d-flex align-items-center gap-1" 
                                                                                    onClick={ () => showForm(true) }
                                                                                >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                    <path d="M3 14C3 9.02944 7.02944 5 12 5C16.9706 5 21 9.02944 21 14M17 14C17 16.7614 14.7614 19 12 19C9.23858 19 7 16.7614 7 14C7 11.2386 9.23858 9 12 9C14.7614 9 17 11.2386 17 14Z" stroke="#443792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                </svg> 
                                                                                View Mail
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Divider /> */}
                                                                                <Dropdown.Item 
                                                                                    eventKey={2} 
                                                                                    className="d-flex align-items-center gap-1 text-danger"
                                                                                    onClick={ () => handleDelete(item.id) }
                                                                                >
                                                                                    <FontAwesomeIcon icon="trash" /> Delete
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) 
                                                    : 
                                                    (
                                                        <tr>
                                                            <td colSpan={5} className="text-center text-muted">No data found</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Collapse>
                        </Container>
                    </Col>
                </Row>

                <DrewsModal
                    show={withdrawal}
                    onHide={() => showForm(false)}
                    size="md"
                    dismissable={true}
                    title="New mail"
                >
                    <Form>
                        <p className="text-right">
                            <a 
                                download={true} 
                                href="https://bs-staging.wallx.co/biz/course_material/Contact_List.xlsx"
                                // href={process.env.NODE_ENV === 'production' ? 
                                // process.env.REACT_APP_BASE_URL_PROD+'/template_download/' : 
                                // process.env.REACT_APP_BASE_URL_DEV+'/template_download/'}
                                
                                target="_blank"
                            >
                                Download template
                            </a>
                        </p>
                        <Row>
                            {/* <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label>Recipient</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Recipient"
                                    name="recipient"
                                    onChange={handleFormInput}
                                />
                            </Form.Group> */}
                            <Form.Group as={Col} md={12} lg={12} sm={12} className="mb-3">
                                <Form.Label className="mb-0 text-sm">Contact list</Form.Label>
                                <label htmlFor="contact_list" className="custom-file-select form-control">
                                    <FontAwesomeIcon icon="fa fa-cloud" /> Upload document
                                    <input
                                        type="file"
                                        name="contact_list"
                                        onChange={handleFileSelect}
                                        size="lg"
                                        id="contact_list"
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    />
                                </label>
                                <p className="px-1 m-0 text-secondary text-sm">Only .xlsx file type is allowed. Max files size 2mb</p>
                                <button 
                                    type="button" 
                                    className="btn btn-plain btn-sm text-white bg-primary"
                                    onClick={uploadContacts}
                                    disabled={ contactlist === ' ' || contactlist === undefined ? true : false }
                                >
                                    Upload Contacts <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </button>
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label>Type</Form.Label>
                                <Form.Select
                                    required
                                    name="type"
                                    className="form-control"
                                    onChange={handleFormInput}
                                >
                                    <option value="">---Select---</option>
                                    <option value="personalized">Personalized</option>
                                    <option value="broadcast">Broadcast</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Select Customers</Form.Label>
                                <Select 
                                    name="emails" 
                                    options={email_contacts}
                                    onChange={ handleSelect }
                                    isMulti
                                    closeMenuOnSelect={false}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            height: "35px",
                                            paddingLeft: "0px"
                                        }), 
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label>Subject</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Subject"
                                    name="subject"
                                    onChange={handleFormInput}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label>Message</Form.Label>
                                <textarea
                                    required
                                    rows={4}
                                    placeholder="Message"
                                    name="message"
                                    className="form-control"
                                    onChange={handleFormInput}
                                ></textarea>
                            </Form.Group>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Button 
                                    type="button" 
                                    variant="primary" 
                                    className="form-control"
                                    onClick={ handleMailsending }
                                >
                                    Send mail <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>
            </>
        </Layout>
    );
}

export default Messaging;