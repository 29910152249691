import { Container, Nav, Navbar, NavDropdown, Badge, Button } from "react-bootstrap";
import avatar from '../assets/images/avatar.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { changeCurrency, logout, sidebarToggle } from "../redux/slices/auth";
import { useDispatch, useSelector } from'react-redux'
import { getUserNotifications, markAsRead } from "../redux/slices/users";
import { useState, useEffect } from "react";
import { getMemberNotifications, markAsReadItem } from "../redux/slices/managers.slice";
import dateFormatter from "../utils/dateFormatter";
import NavCurrencyDropdown from "./NavCurrencyDropDown";

const AppTopBar = () => {
    
    const dispatch = useDispatch()

    const { user, isLoggedIn, isManager, sidebar, currency } = useSelector((state) => state.auth)
    const { business } = useSelector((state) => state.user)
    const [notifications, setNotifications] = useState([])
    const [show_notification, setShow] = useState(false)

    // Get notifications when the component mounts
    useEffect(()=>{
        if(isLoggedIn){
            loadNotifications()
        }
        // console.log("USER", user)
    },[isLoggedIn]);

    const signuOut = () => {
        dispatch(logout())
        // .then( (e) => console.log(e))
    }

    const menuToggle = () => {
        dispatch(sidebarToggle())
    }

    const loadNotifications = () => {
        const method = !isManager ?  getUserNotifications() : getMemberNotifications('False')
        dispatch(method)
        .then((res) => {
            if (res.payload?.results) {
                setNotifications(res.payload?.results)
            }
        })
    }

    const readNotification = (id) => {
        const method = !isManager ? markAsRead(id) : markAsReadItem(id)
        dispatch(method)
        .then((res) => {
            if (res.payload?.success === true) {
                loadNotifications()
            }
        })
    }

    const handleCurrencyToggle = (event) => {
        dispatch(changeCurrency(event.value))
    }

    return (
      <Navbar collapseOnSelect expand="lg" bg="white" variant="light" className="shadow-sm no-print" sticky="top" hidden={sidebar}>
        <Container className="container-md">
            <Button onClick={ menuToggle } className="me-auto d-block d-sm-none">
                <FontAwesomeIcon icon={'bars'} />
            </Button>
            <Navbar.Toggle className="ms-auto" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    {
                        business?.business_type === 'Starter Business' ? (
                            <div className="navbar-notification">
                                <p>
                                   You are on a Starter business, Kindly upgrade to avoid limitations on your transaction 
                                </p>
                            </div>
                        ) : null
                    }
                </Nav>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
                <Nav>
                    <Nav.Link>
                        <NavCurrencyDropdown onChange={ handleCurrencyToggle } />
                    </Nav.Link>
                    {/* <Nav.Link onClick={() => setShow(!show_notification)} className="custom-bordered p-2 me-2 notification-button"> */}
                    <Nav.Link onClick={() => setShow(!show_notification)}>
                        <div className="custom-borderedd me-2 notification-button px-2 py-2">
                            <FontAwesomeIcon icon="far fa-bell" style={{ fontSize: 25, color: "#828282" }} className="text-center" />
                            <sup>
                                <Badge 
                                    className="badge badge-danger rounded-circle" 
                                    style={{
                                        height: "25px", width: "25px", 
                                        position: "absolute", right: "-14px", top: "-10px", 
                                        display: "flex", alignItems: "center", 
                                        justifyContent: "center", fontWeight: "600",
                                        fontSize: "14px"
                                    }}
                                >
                                    {notifications && notifications.length}
                                </Badge>
                            </sup>    
                            {
                                show_notification ? (
                                    <div className="notification-dropdown">
                                        <h6>Notifications</h6>
                                        <hr/>
                                        <ul>
                                            {
                                                notifications && notifications.map((item, index) => ( 
                                                    !item.read ? (
                                                        <li key={index}>
                                                            <button 
                                                                type="button" 
                                                                className="btn-plain"
                                                                onClick={() => readNotification(item.id)}
                                                            >
                                                                <strong className="mb-0">{item.title}</strong>
                                                                <p className="m-0">{item.message}</p>
                                                                <p className="text-right m-0 time">{dateFormatter(item.date)}</p>
                                                            </button>
                                                        </li>
                                                    ) : null
                                                ))
                                            }
                                        </ul>
                                    </div>
                                ) : null
                            }                    
                        </div>
                    </Nav.Link>
                    <NavDropdown 
                        id="nav-dropdown"
                        className="custom-bordered dropdown-menu-lg-start"
                        title={
                            <span className="d-inline-flex align-items-center">
                                <span className="nav-avatar">
                                    {
                                        user?.user_type === 'owner' ? (
                                            <img
                                                className="thumbnail-image rounded-circle" 
                                                src={business?.profile_photo || user?.profile_photo} 
                                                alt="avatar"
                                            />
                                        )
                                        : 
                                            <img
                                                className="thumbnail-image rounded-circle" 
                                                src="https://via.placeholder.com/30"
                                                alt="avatar"
                                            />
                                    }
                                </span>
                                <span className="px-2">
                                    {
                                        isLoggedIn  && user.user_type === 'owner' ? (
                                            business && business.store_name ? business.store_name : ''
                                        )
                                        : user?.store_name || 'Manager'
                                    }
                                </span>
                            </span>
                        } 
                    >
                        {
                            user?.user_type === 'owner' ? (
                                <>
                                    <NavDropdown.Item href="/settings">
                                        <FontAwesomeIcon icon='fa fa-cog' style={{fontSize: "20px"}}/> Account Setting
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="/managers" className="text-success">
                                        <i className="lni lni-users" style={{fontSize: "25px"}}></i>
                                        <span className="px-2">My Managers</span>
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="subscriptions" className="text-secondary">
                                        <i className="lni lni-database"></i> <span className="px-1">My Subscription</span>
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="/settings" className="text-primary">
                                        <i className="lni lni-printer"></i> <span className="px-1">Print Banner</span>
                                    </NavDropdown.Item>
                                </>
                            ) : (
                                <>
                                    <NavDropdown.Item href="/members/change-password" className="text-primary">
                                        <FontAwesomeIcon icon="lock"/>
                                        <span className="px-1">Change Password</span>
                                    </NavDropdown.Item>
                                </>
                            )
                        }
                        <NavDropdown.Divider />
                        <NavDropdown.Item 
                            className="text-primary"
                            onClick={signuOut}
                        >
                            <FontAwesomeIcon icon='fa fa-right-from-bracket' style={{fontSize: "20px"}}/> <span className="px-1">Logout</span>
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Container>
      </Navbar>
    );
}

export default AppTopBar;