import { Row, Col, Container, Card, Button, Table, Badge, Form, Spinner, Modal } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/table/Paginator";
import { useEffect, useState } from "react";
import DrewsModal from "../../components/modal/DrewsModal";
import { clearMessage } from "../../redux/slices/message";
import { useDispatch, useSelector } from "react-redux";
import { getLoyaltyPaycodes, checkLoyaltyBalance, applyLoyaltyPayCode, createLoyaltyPaycode, getLoyaltyPaycodeHistory, getOTP } from "../../redux/slices/account.service";
import Swal from "sweetalert2";
import formatCurrency from "../../utils/currencyFormmter";
import dateFormatter from "../../utils/dateFormatter";
import RecordFilter from "../../components/RecordFilter";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LoyaltyPaycode = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    // const { user, isLoggedin } = useSelector((state) => state.auth)
    const { message } = useSelector((state) => state.message)
    const { business } = useSelector((state) => state.user)
    const { user } = useSelector((state) => state.auth)

    const [modalShow, setModalShow] = useState(false)
    const [showForm, setForm] = useState(false)
    const [showBalance, setShowBalance] = useState(false)
    const [payload, setPayload] = useState({
        page: 1,
        from_date: '',
        to_date: '',
        status: '',
        download: '',
        filter_by: '',
        sort_by: '',
        filter: '',
        gateway: '',
    })
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [genVoucher, setGenVoucher] = useState(false)
    const [paycodes, setPaycodes] = useState([])
    const [errors, setErrors] = useState({})
    const [analytics, setAnalytics] = useState({})
    const [paycode, setPaycode] = useState({})
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    const [page, setPage] = useState(1)
    const [paycodeHistory, setPaycodeHistory] = useState([])
    const [showVoucherCard, setShowVoucherCard] = useState(false)
    const [base64Image, setBase64Image] = useState('');
    const [paycode_analytics, setPaycodeAnalytics] = useState({})
    const [theme, setTheme] = useState({
        primaryColor: "#76609F",
        secondaryColor: "",
        textColor: ""

    })

    useEffect(() => {
        if (message && message.status === 'False') {
            Swal.fire({ 
                icon: 'error',
                text: message.message
            })
        }
        else if (message && message.status === 'True') {
            Swal.fire({
                icon: 'success',
                title: message.message
            })
            .then(() => setGenVoucher(false))
        }
        dispatch(clearMessage())
        loadVouchers()
    }, [message, dispatch])

    useEffect(() => {
        loadVoucherHistory()
    },[payload.paycode_id])

    useEffect(() => {
        loadVouchers()
    }, [payload.page, payload.top])

    const selectPaycode = (item, option) => {
        setPaycode(item)
        
        if(option === 'print') {
            setShowVoucherCard(true)
            convertLogo(business?.profile_image)
        }
        else {
            setPayload((prevState) =>  ({...prevState, paycode_id: item.id}))
            setModalShow(true)
        }
    }

    const handleInput = (event) => {
        const {name, value} = event.target
        if(name === 'dob') {
            const dateParts = value.split('-');
            const dob = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
            setPayload((prevState) => ({...prevState, [name]: dob}))
        }
        else if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^\d.]/g, ''))
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else if(name === 'secondaryColor' || name === 'primaryColor') {
            console.log("Color", value)
            setTheme((prevState) => ({...prevState, [name]: value}))
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
    }


    const createPayCode = () => {
        setLoading(true)
        dispatch(createLoyaltyPaycode({
            customer_email: payload.email, 
            customer_phone: payload.phone_number,
            customer_name: payload.customer_name,
            otp: payload.otp,
            // secret: payload.secret,
            amount: payload.amount,
        }))
        .then((response) => {
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                .then(() => {
                    setGenVoucher(false)
                    loadVouchers(page)
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const claimVoucher = () => {
        if(!payload.amount && !payload.code && !payload.secret)  { return }

        setLoading(true)

        if(user?.user_type === 'owner') {
            dispatch(applyLoyaltyPayCode(payload))
            .then((response) => {
                if(response.payload?.success === true) {
                    Swal.fire({
                        icon: 'success',
                        text: response.payload?.message
                    })
                    .then(() => setForm(false))
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: response.payload?.message
                    })
                }
            })
            .finally(() => setLoading(false))
        }
        else {
            let requestPayload = {}
            requestPayload.pin = payload.code
            requestPayload.amount = payload.amount
            requestPayload.secret = payload.secret
            requestPayload.merchant_id = user?.merchant_id
            axios.post(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV}/api-v1/claim_paycode/`, requestPayload)
            .then((response) => {
                if(response.data?.success === true) {
                    Swal.fire({
                        icon: 'success',
                        text: response.data?.message
                    })
                    .then(() => setForm(false))
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: response.data?.message
                    })
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    text: error.response?.data?.message
                })
            })
            .finally(() => setLoading(false))

        }
    }

    const voucherBalance = () => {
        if(!payload.paycode && !payload.secret_word)  {  return }
        let requestPayload  = payload
        requestPayload.user_type = user?.user_type
        setLoading(true)
        dispatch(checkLoyaltyBalance(requestPayload))
        .then((response) => {
            if(response.payload?.success) {
                Swal.fire({
                    icon: 'info',
                    text: response.payload?.message
                })
                .then(() => setShowBalance(false))
            }
        })
        .finally(() => setLoading(false))
    }

    const loadVouchers = () => {
        let requestPayload  = payload
        requestPayload.user_type = user?.user_type
        setLoading(true)
        dispatch(getLoyaltyPaycodes(requestPayload))
        .then((response) => {
            if(response.payload?.results) {
                setPaycodes(response.payload.results)
            }
            if(response.payload?.analytics) {
                setAnalytics(response.payload.analytics)
            }

            if(response.payload?.next || response.payload?.previous) {
                const data = {
                    next: response.payload?.next,
                    prev: response.payload?.previous,
                }
                setpaginationData(data)
            }
        })
        .finally(() => setLoading(false))
    }
    
    const loadVoucherHistory = () => {
        let requestPayload  = payload
        requestPayload.user_type = user?.user_type
        setLoading(true)
        dispatch(getLoyaltyPaycodeHistory(requestPayload))
        .then((response) => {
            if(response.payload?.analytics) {
                setPaycodeAnalytics(response.payload.analytics)
            }

            if(response.payload?.results) {
                setPaycodeHistory(response.payload.results)
            }
        })
        .finally(() => setLoading(false))
    }
    
    const handlePagination = (data) => {
        setPayload({...payload, page: data})
    }

    const handleSearch = () => {
        loadVouchers()
    }

    const generatePdf = () => {
        const input = document.getElementById('pdf-content');
        
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            
            // Calculate the width and height to fit the PDF
            const imgWidth = 190; // A4 width in mm
            const pageHeight = pdf.internal.pageSize.height;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;

            let position = 0;

            // Add the image to the PDF
            pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            
            // Save the PDF
            pdf.save(`${paycode.customer_name.split(' ').join('_')}_LoyaltyVoucher.pdf`);
        });
    }

    const convertLogo = (logo) =>  {
        fetch(logo)
        .then(response => response.blob())
        .then(blob => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setBase64Image(base64String);
            };
            reader.readAsDataURL(blob);
        })
        .catch(error => {
            console.error('Error converting image to base64:', error);
        });
    }

    const requestOTP = () => {
        Swal.fire({
            icon: 'info',
            text: 'An OTP will be sent to your email. Do you wish to proceed?',
            confirmButtonText: 'Yes, Proceed',
            cancelButtonText: 'No, Abort',
            showCancelButton: true,
            showConfirmButton: true
        })
        .then((result) => {
            if(result.isConfirmed) {
                setLoading(true)
                dispatch(getOTP()) 
                .then((response) => {
                    if(response?.payload?.success === true) {
                        Swal.fire({
                            icon: 'success',
                            text: response?.payload?.message || 'OTP sent successfully.'
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: response?.payload?.message || 'Could not send OTP.'
                        })
                    }
                })
                .finally(() => setLoading(false))
            }
        })
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Col md={12} className="d-flex align-items-center justify-content-between flex-wrap">
                    <div>
                        <h3 className="m-0">Loyalty PayCode</h3>
                        <p>Shoppers are Customers</p>
                    </div>
                    <div>
                        <Button 
                            type="button" 
                            variant="secondary" 
                            className="border-0 mb-2 me-2 text-white"
                            onClick={ () => navigate('/loyalty-voucher/history')}
                        >
                            <span className="ps-2">Transaction History</span>
                        </Button>
                        <Button 
                            type="button" 
                            variant="primary" 
                            className="border-0 mb-2 mx-1"
                            onClick={ () => setShowBalance(true)}
                        >
                            <span className="ps-2">Check Voucher Balance</span>
                        </Button>
                        {
                            user?.user_type === 'owner' ? (
                                <Button 
                                    type="button" 
                                    variant="primary" 
                                    className="border-0 mx-2 mb-2"
                                    onClick={ () => setGenVoucher(true)}
                                >
                                    <span className="ps-2">Create Loyalty Voucher</span>
                                </Button>
                            ) : null
                        }
                        <Button 
                            type="button" 
                            variant="primary" 
                            className="border-0 mb-2"
                            onClick={ () => setForm(true)}
                        >
                            <span className="ps-2">Accept Voucher</span>
                        </Button>
                    </div>
                </Col>

                <div className="mt-4">
                    {
                        loading ? (
                            <div className="page-loader">
                                <Spinner size="lg" variant="primary"/>
                            </div>
                        ) : null
                    }
                    <Row className="data-card mb-4">
                        <Col md={3} sm={12} className="mb-2">
                            <Card className="border-0 shadow-sm bg--light">
                                <Card.Body className="d-flex align-items-center gap-3 p-3">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={'gifts'} style={{fontSize: '24px'}} />
                                    </div>
                                    <div>
                                        <h5 className="m-0">{formatCurrency(analytics?.total_issued_paycodes).formatted}</h5>
                                        <p className="m-0">Total created/members</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12} className="mb-2">
                            <Card className="border-0 shadow-sm bg--success">
                                <Card.Body className="d-flex align-items-center gap-3 p-3">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={'gifts'} style={{fontSize: '24px'}} />
                                    </div>
                                    <div>
                                        <h5 className="m-0">{formatCurrency(analytics?.total_paycode_value).with_currency}</h5>
                                        <p className="m-0">Cash Flow</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12} className="mb-2">
                            <Card className="border-0 shadow-sm bg--primary">
                                <Card.Body className="d-flex align-items-center gap-3 p-3">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={'gifts'} style={{fontSize: '24px'}} />
                                    </div>
                                    <div>
                                        <h5 className="m-0">{formatCurrency(analytics?.total_used_amount).with_currency}</h5>
                                        <p className="m-0">Total Used</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3} sm={12} className="mb-2">
                            <Card className="border-0 shadow-sm bg--secondary">
                                <Card.Body className="d-flex align-items-center gap-3 p-3">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={'gifts'} style={{fontSize: '24px'}} />
                                    </div>
                                    <div>
                                        <h5 className="m-0">{formatCurrency(analytics?.total_expired_count).formatted}</h5>
                                        <p className="m-0">Total Expired</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <RecordFilter
                        handleInput={handleInput}
                        handleSearchSubmit={handleSearch}
                        params={[
                            {label: 'Customer Name', value: 'customer_name'},
                            {label: 'Customer Email', value: 'customer_email'},
                            {label: 'Customer Phone', value: 'customer_phone'},
                            {label: 'Status', value: 'status'},
                            {label: 'Date', value: 'date'},
                            {label: 'Top Shoppers', value: 'top_shoppers'},
                        ]}
                    />

                    <Card className="border-0 shadow-sm">
                        <Card.Body className="table-responsive-lg">
                            <Table size="sm" className="table-color">
                                <thead>
                                    <tr>
                                        <th>Customer Name</th>
                                        <th>Customer Email</th>
                                        <th>Customer Phone</th>
                                        <th>Amount</th>
                                        <th>Balance</th>
                                        <th>Date Created</th>
                                        <th>Token</th>
                                        <th>Print</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        paycodes?.length ? (
                                            paycodes.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {item.customer_name}
                                                        <button
                                                            style={{border: "none", display: "block", backgroundColor: "transparent"}}
                                                            className="text-primary p-0"
                                                            onClick={ () => navigate('/loyalty-voucher/history/'+item.id) }
                                                            // onClick={ () => selectPaycode(item, 'history') }
                                                        >
                                                            Voucher History 
                                                        </button>
                                                    </td>
                                                    <td>{item.customer_email}</td>
                                                    <td>{item.customer_phone}</td>
                                                    <td>
                                                        {formatCurrency(item.top_up_amount).with_currency}
                                                    </td>
                                                    <td>
                                                        {formatCurrency(item.balance).with_currency}
                                                    </td>
                                                    <td>
                                                        {dateFormatter(item.created_at)}
                                                    </td>
                                                    <td>
                                                        {item.code}
                                                    </td>
                                                    <td className="text-center">
                                                        <button
                                                            style={{border: "none", display: "block", backgroundColor: "transparent"}}
                                                            className="text-primary p-0 text-center"
                                                            onClick={ () => selectPaycode(item, 'print') }
                                                        >
                                                            <FontAwesomeIcon icon={'money-check'} />
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <p className="d-flex m-0 align-items-center justify-content-between">
                                                            <Badge 
                                                                className={!item.expired ? 'badge-active' : 
                                                                (item.used_amount > 0 === 'used' ? 'badge-used' : 
                                                                (!item.expired ? 'badge-failed' : 'badge-pending')
                                                                )}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                    <circle cx="5" cy="5" r="5" fill={
                                                                        !item.expired ? "#008000" : 
                                                                        (item.used_amount > 0 ? "#828282" : "#FAB941")
                                                                    }
                                                                    />
                                                                </svg>
                                                                <span className="ps-1">
                                                                    {!item.expired ? 'Active' : (item.used_amount > 0 ? 'Used' : 'Expired')}
                                                                </span>
                                                            </Badge> 
                                                        </p>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                        :
                                        (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        paycodes?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator
                                    next={paginationData.next}
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </div>

                <Modal 
                    size="lg" 
                    show={showVoucherCard} 
                    onHide={() => setShowVoucherCard(false)}
                >
                    <Modal.Body>
                        <div>
                            <h5>Change theme</h5>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={6}>
                                    <Form.Label htmlFor="primaryColor" className="me-3">
                                        Primary Color
                                        <Form.Control type="color" name="primaryColor" id="primaryColor" onChange={handleInput} />
                                    </Form.Label>
                                    <Form.Label>
                                        Secondary Color
                                        <Form.Control type="color" name="secondaryColor"  id="secondaryColor" onChange={handleInput}/>
                                    </Form.Label>
                                </Form.Group>
                            </Row>
                            <div className="voucher-pdf" id="pdf-content">
                                <div class="voucher-card m-auto" >
                                    <div class="voucher-card-row">
                                        <div class="voucher-card-left" style={{background: theme.primaryColor}}>
                                            <div class="ribbon-top">
                                                <img src={require("../../assets/images/ribbon-left-top.png")} alt="ribbon" width="190" />
                                            </div>
                                            <div class="ribbon-bottom-left">
                                                <img src={require("../../assets/images/ribbon-left-bottom.png")} alt="ribbon" width="180" />
                                            </div>
                                            <div class="card-label">
                                                <h1>LOYALTY CARD</h1>
                                                <p>POWERED BY GIFTEASE</p>
                                            </div>
                                        </div>
                                        <div class="voucher-card-right" style={{background: theme.secondaryColor}}>
                                            <div class="ribbon">
                                                <img src={require("../../assets/images/ribbon-right.png")} alt="ribbon" width="100" />
                                            </div>
                                            <div class="merchant-logo-wrapper">
                                                <div class="merchant-logo" style={{border: `3px solid ${theme.primaryColor}`}}>
                                                    <img src={base64Image} alt="ribbon" />
                                                </div>
                                            </div>
                                            <h5 class="merchant-name-color" style={{color: theme.primaryColor}}>MERCHANT NAME:</h5>
                                            <div class="merchant-name" style={{border: `1px dashed ${theme.primaryColor}`}}>{business?.store_name}</div>
                                            <div class="customer-name" style={{background: theme.primaryColor}}>
                                                <p>CUSTOMER NAME: <span>{paycode.customer_name}</span></p>
                                            </div>
                                            <h5 class="paycode-header">YOUR LOYALTY PAYCODE:</h5>
                                            <div class="paycode-box">
                                                {
                                                    String(paycode.code).split('').map((pin, index) => (
                                                        <span key={index} style={{border: `.5px solid ${theme.primaryColor}`}}>{pin}</span>
                                                    ))
                                                }
                                            </div>
                                            <p class="text-center text-danger">Valid Till :  {dateFormatter(paycode.expires_at)}</p>
                                            <div class="voucher-card-footer">
                                                <p> <strong>Display Contact Details</strong></p>
                                                <p>08023*****9 , 21 Nyesi Ibrahim Tella, Oral Estate, Lagos, Nigeria</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal.Footer>
                            <button className="btn-plain btn btn-sm bg-primary text-white" onClick={generatePdf}>Download PDF</button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>

                <DrewsModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    dismissable={true}
                    title="Paycode History"
                >
                    <Row>
                        <Col md={12}>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <div>
                                    Total Voucher Count <h5>{formatCurrency(paycode_analytics?.total_voucher_count).formatted}</h5>
                                </div>
                                <div>
                                    Total Voucher Amount <h5>{formatCurrency(paycode_analytics?.total_voucher_amount).with_currency}</h5>
                                </div>
                            </div>
                            <Table size="sm" className="table-color">
                                <thead>
                                    <tr>
                                        <th>Transaction Type</th>
                                        <th>Amount</th>
                                        <th>Balance Before</th>
                                        <th>Balance After</th>
                                        <th>Fee</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        paycodeHistory?.length ? (
                                            paycodeHistory.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.transaction_type}</td>
                                                    <td>
                                                        {formatCurrency(item.amount).with_currency}
                                                    </td>
                                                    <td>
                                                        {formatCurrency(item.amount_before).with_currency}
                                                    </td>
                                                    <td>
                                                        {formatCurrency(item.amount_after).with_currency}
                                                    </td>
                                                    <td>
                                                        {formatCurrency(item.fee).with_currency}
                                                    </td>
                                                    <td>{dateFormatter(item.created_at)}</td>
                                                    <td>{item.status}</td>
                                                </tr>
                                            ))
                                        )
                                        :
                                        (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </DrewsModal>

                <DrewsModal
                    show={showBalance}
                    onHide={() => setShowBalance(false)}
                    size="md"
                    dismissable={true}
                    title="Voucher Balance"
                >
                    <Form>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Voucher Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="PayCode"
                                    name="paycode"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    minLength={6}
                                    maxLength={6}
                                />
                                {
                                    errors['paycode'] && 
                                    <Form.Text className="text-danger">{errors['paycode']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Secret Word</Form.Label>
                                <Form.Control
                                    type="password"
                                    required
                                    placeholder="Secret Word"
                                    name="secret_word"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['secret_word'] && 
                                    <Form.Text className="text-danger">{errors['secret_word']}</Form.Text>
                                }
                            </Form.Group>
                                <p className="text-danger text-sm">
                                    {errorMessage}
                                </p>
                            <Form.Group 
                                as={Col} 
                                md="12" 
                                controlId="validationCustom011" 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={voucherBalance}
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    Check Balance <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>

                <DrewsModal
                    show={genVoucher}
                    onHide={() => setGenVoucher(false)}
                    size="md"
                    dismissable={true}
                    title="Create Loyalty Voucher"
                >
                    <Form>
                        <p className="text-sm">Generate new loyalty voucher.</p>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Customer Name <span><small>(optional)</small></span> </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Andrew"
                                    name="customer_name"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['customer_name'] && 
                                    <Form.Text className="text-danger">{errors['customer_name']}</Form.Text>
                                }
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Customer Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    required
                                    placeholder="example@xyz.com"
                                    name="email"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['email'] && 
                                    <Form.Text className="text-danger">{errors['email']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Customer Phone Number</Form.Label>
                                <Form.Control
                                    type="tel"
                                    required
                                    placeholder="090123456789"
                                    name="phone_number"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    min={1}
                                />
                                {
                                    errors['phone_number'] && 
                                    <Form.Text className="text-danger">{errors['phone_number']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="5000"
                                    name="amount"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                />
                                {
                                    errors['amount'] && 
                                    <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                }
                            </Form.Group>
                            
                            <Form.Group as={Col} md="12" className="mb-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <Form.Label className="mb-0">
                                    <span>OTP</span>  
                                    </Form.Label>
                                    <Button 
                                        type="button" 
                                        variant="outline-primary" 
                                        size="sm" 
                                        className="mb-1 w-25" 
                                        onClick={requestOTP}
                                    >Request OTP</Button> 
                                </div>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="OTP"
                                    name="otp"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['otp'] && 
                                    <Form.Text className="text-danger">{errors['otp']}</Form.Text>
                                }
                            </Form.Group>
                            <Form.Group 
                                as={Col} 
                                md="12" 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={createPayCode}
                                    // disabled={Object.keys(errors).length > 0}
                                >
                                    Create Voucher <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>

                <DrewsModal
                    show={showForm}
                    onHide={() => setForm(false)}
                    size="md"
                    dismissable={true}
                    title="Claim PayCode"
                >
                    <Form>
                        <p className="text-sm">Claim Paycode.</p>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">PayCode</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="PayCode"
                                    name="code"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    minLength={6}
                                    maxLength={6}
                                />
                                {
                                    errors['code'] && 
                                    <Form.Text className="text-danger">{errors['code']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Secret Word</Form.Label>
                                <Form.Control
                                    type="password"
                                    required
                                    placeholder="Secret Word"
                                    name="secret"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['secret'] && 
                                    <Form.Text className="text-danger">{errors['secret']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="0.00"
                                    name="amount"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                />
                                {
                                    errors['amount'] && 
                                    <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                }
                            </Form.Group>
                                <p className="text-danger text-sm">
                                    {errorMessage}
                                </p>
                            <Form.Group 
                                as={Col} 
                                md="12" 
                                controlId="validationCustom011" 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={claimVoucher}
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    Claim Voucher <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>
            </Container>
        </Layout>
    );
}

export default LoyaltyPaycode;