import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Row, Col, Button} from "react-bootstrap";

const InventoryFilter = (props) => {

    const handleInput = props.handleInput
    const handleSearchSubmit = props.handleSearchSubmit

    const handleEntry = (e) => {
        handleInput(e)
    }

    const handleSubmit = () => {
        handleSearchSubmit()
    }

    return (
        <Row className="mb-3">
            <Form.Group as={Col} md={4} className="mb-2">
                <Form.Control
                    type="search"
                    placeholder="Search by Product name, SKU..."
                    onChange={ handleEntry }
                    name="filter"
                />
            </Form.Group>
            <Form.Group as={Col} md={3} className="mb-2">
                <div className="input-group">
                    <span className="input-group-text">By</span>
                    <select className="form-select" name="filter_by" onChange={ handleEntry }>
                        <option value="all">All</option>
                        <option value="sku">SKU</option>
                        <option value="name">Product Name</option>
                        <option value="amount">Amount</option>
                        <option value="status">Status</option>
                        <option value="date">Date</option>
                    </select>
                </div>
            </Form.Group>
            <Form.Group as={Col} md={3} className="mb-2">
                <div className="input-group">
                    <span className="input-group-text">Sort By</span>
                    <select className="form-select" name="sort_by" onChange={ handleEntry }>
                        <option value="DESC">Descending</option>
                        <option value="ASC">Ascending</option>
                    </select>
                </div>
            </Form.Group>
            <Form.Group as={Col} md={2} className="mb-2">
                <Button 
                    type="button" 
                    variant="primary" 
                    onClick={ handleSubmit }
                >
                    <FontAwesomeIcon icon="fa fa-search" style={{fontSize: '17px'}} />
                    <span className="px-2">Search</span>
                </Button>
            </Form.Group>
        </Row>
    );
}

export default InventoryFilter;