import { ProgressBar } from "react-bootstrap";

const DrewsProgressBar = (props) => {
    return (
        <div className="w-100 progress-container">
            <div className="progress-container-item">
                <span className="value-stack">5</span> <ProgressBar now={props.excellent} variant="secondary"  />
            </div>
            <div className="progress-container-item">
                <span className="value-stack">4</span> <ProgressBar now={props.better} variant="secondary"  />
            </div>
            <div className="progress-container-item">
                <span className="value-stack">3</span> <ProgressBar now={props.very_good} variant="secondary"  />
            </div>
            <div className="progress-container-item">
                <span className="value-stack">2</span> <ProgressBar now={props.good} variant="secondary"  />
            </div>
            <div className="progress-container-item">
                <span className="value-stack">1</span> <ProgressBar now={props.poor} variant="secondary"  />
            </div>
        </div>
    );
}
export default DrewsProgressBar;